<script setup>
import api from "../api/api"
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
import BaseInput from "./UI/BaseInput.vue"
import { flashMessage } from "../functions.js"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
</script>

<template>
  <div class="box">
    <form @submit.prevent="" method="">
      <header>
        <h2>Company Configuration</h2>
        <h2>CC01</h2>
      </header>
      <div class="grid-container">
        <label for="">Core System</label>
        <select class="form-control-select" v-model="coreSystem" style="margin-bottom: 20px">
          <option>Symitar</option>
          <option>FiservDNA</option>
          <option>Demo</option>
        </select>
        <label for="">Parameter</label>
        <select class="form-control-select" style="margin-bottom: 20px">
          <option>Member Number</option>
          <option>FileName</option>
          <option>Other</option>
        </select>
        <label for="">Site ID</label>
        <BaseInput id="" style="margin-bottom: 20px" v-model="siteID" />
      </div>
      <div class="button-column" style="display: inline-block">
        <ButtonSubmit type="button" label="Edit" @click="modalOpen = !modalOpen" />
      </div>
    </form>

    <dialog class="dialog-symitar" :open="modalOpen && symitarModal">
      <div class="dialog-backdrop"></div>
      <div class="dialog-body">
        <button type="button" class="close" @click="closeEvent">
          <span>&times;</span>
        </button>
        <form @submit.prevent="updateCompanyConfig">
          <h4 class="dialog-header">Symitar Configuration</h4>

          <div class="dialog-grid-container">
            <label for="">Device Type</label>
            <BaseInput
              v-model="symitarObj.DeviceType"
              placeholder="MNET"
              type="text"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Device Number</label>
            <BaseInput
              placeholder="1234"
              v-model="symitarObj.DeviceNumber"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Base URL</label>
            <BaseInput
              placeholder="HTTPS://"
              v-model="BaseUrl"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Poweron URL</label>
            <BaseInput
              placeholder="HTTPS://"
              v-model="symitarObj.PoweronUrl"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Admin Password</label>
            <BaseInput
              placeholder="3XXY98ABC"
              v-model="symitarObj.AdminPassword"
              id=""
              style="margin-bottom: 20px" />
          </div>
          <br />
          <div class="button-group" style="margin-top: 20px">
            <ButtonSubmit label="Save" />
            <ButtonCancel
              label="Cancel"
              type="button"
              @click="cancelCompanyConfig('Symitar Configuration')" />
              <ButtonSubmit label="Test Connection" />
          </div>
        </form>
      </div>
    </dialog>
    <dialog class="dialog-fiserve" :open="modalOpen && fiserveModal">
      <div class="dialog-backdrop"></div>
      <div class="dialog-body">
        <form @submit.prevent="updateCompanyConfig">
          <button type="button" class="close" @click="closeEvent">
            <span>&times;</span>
          </button>
          <h4 class="dialog-header">Fiserv Configuration</h4>

          <div class="dialog-grid-container">
            <label for="">Username</label>
            <BaseInput
              placeholder=""
              type="text"
              v-model="fiserveObj.UserName"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Password</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.Password"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">ApplId</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.ApplId"
              required
              id=""
              style="margin-bottom: 20px" />
              <label for="">CoreAPIEndpointURL</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.CoreAPIEndpointURL"
              required
              id=""
              style="margin-bottom: 20px" />
              <label for="">SAFEndpointURL</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.SAFEndpointURL"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Device Id</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.DeviceId"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">ProdDefCd</label>
            <BaseInput
              placeholder=""
              v-model="fiserveObj.ProdDefCd"
              id=""
              style="margin-bottom: 20px" />
            <label for="">ProdEnvCd</label>
            <BaseInput
              placeholder=""
              id=""
              v-model="fiserveObj.ProdEnvCd"
              style="margin-bottom: 20px" />
          </div>
          <br />
          <div class="button-group" style="margin-top: 20px">
            <ButtonSubmit label="Save" />
            
            <ButtonCancel
              label="Cancel"
              type="button"
              @click="cancelCompanyConfig('Fiserve Configuration')" />
              <ButtonSubmit label="Test Connection" />
          </div>
        </form>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: "CompanyConfiguration",
  component: [ButtonSubmit, ClipLoader, BaseInput, ButtonCancel],
  props: ["buttonLabel", "userData", "brandData"],
  data: () => ({
    siteID: "2ynGUBmEwYoGQN2fOzHp",
    company: localStorage.getItem('companyID'),
    modalOpen: false,
    coreSystem: "Symitar",
    tempSymitarObj: {},
    tempFiserveObj: {},
    companyConfigObj: "",
    BaseUrl: "",
    symitarObj: {
      DeviceType: "",
      DeviceNumber: "",
      PoweronUrl: "",
      AdminPassword: "",
    },
    fiserveObj: {
      UserName: "",
      Password: "",
      DeviceId: "",
      ProdDefCd: "",
      ProdEnvCd: "",
      ApplId: "",
      CoreAPIEndpointURL: "",
      SAFEndpointURL: "",
    },
    // fiserveObj: {
    //   username: "GLA45RC",
    //   password: "WwX8qo7GPp",
    //   baseURL: "https://",
    //   deviceID: "INTRFC-GENCLOUD",
    //   prodDefCd: "SAF2011",
    //   prodEnvCd: "Production",
    // },

    currentDate: new Date(),
    setColor: "#ff6a00",
    setSize: "10px",
  }),
  computed: {
    fiserveModal() {
      return this.coreSystem == "FiservDNA" ? true : false
    },
    symitarModal() {
      return this.coreSystem == "Symitar" ? true : false
    },
    isSymitarChange() {
      const tempSymitarObj = JSON.stringify(Object.values(this.tempSymitarObj).sort())
      const symitarObj = JSON.stringify(Object.values(this.symitarObj).sort())

      return tempSymitarObj === symitarObj
    },
    isFiserveChange() {
      const tempFiserveObj = JSON.stringify(Object.values(this.tempFiserveObj).sort())
      const fiserveObj = JSON.stringify(Object.values(this.fiserveObj).sort())

      return tempFiserveObj === fiserveObj
    },
    isBaseUrlChange() {
      return this.companyConfigObj.BaseUrl === this.BaseUrl
    },
  },
  mounted() {
    this.getCompanyConfig()
  },

  methods: {
    async getCompanyConfig() {
      const { data } = await api.post("getCompanyConfiguration", { Company: this.company })

      this.companyConfigObj = data
      this.BaseUrl = data.BaseUrl

      for (const symitar in this.symitarObj) {
        this.symitarObj[symitar] = data[symitar]
      }
      this.tempSymitarObj = { ...this.symitarObj }

      for (const fiserve in this.fiserveObj) {
        this.fiserveObj[fiserve] = data[fiserve]
      }
      this.tempFiserveObj = { ...this.fiserveObj }
    },
    async updateCompanyConfig() {
      const { data } = await api.post("updateCompanyConfiguration", {
        Company: this.company,
        BaseUrl: this.BaseUrl,
        ...this.symitarObj,
        ...this.fiserveObj,
      })
      if (data.affectedRows == 1) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Company Configuration updated successfully"
        )
      }
    },
    cancelCompanyConfig(message) {
      this.getCompanyConfig()
      this.modalOpen = false
      flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        `${message} canceled successfully`
      )
    },
    closeEvent() {
      this.getCompanyConfig()
      this.modalOpen = false
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
header h2 {
  margin: 0;
}
.grid-container {
  display: inline-grid;
  align-items: baseline;
  column-gap: 10px;
  grid-template-columns: minmax(auto, 112px) 255px;
  grid-template-rows: 60px;
}

label {
  text-align: right;
}
.radio-btn {
  margin-top: 5px;
}
.radio-btn input {
  margin-left: 20px;
}
.box {
  align-self: center;
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 2px 10px -4px #000000;
  width: 95%;
}
.btn {
  width: 100%;
  background-color: #e67829;
}

.label1 {
  top: 0px;
  left: 0px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Arial, Helvetica, sans-serif;
}

.input1 {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}
.btn-disabled {
  background-color: #e2e2e2;
  color: #000000;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  font-weight: 500;
  /* margin-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px; */
  margin-top: 5px;
  padding: 8px 10px 8px 10px;
}
label,
input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 10px; */
}
.input-container > label {
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 1rem;
}
* {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}
.permissions-container {
  margin: 1px 0;
  max-width: 800px;
}

.checkbox-container {
  column-count: 2;
  column-gap: 5px;
}

.container {
  display: flex;
  max-width: 800px;
  margin: 0;
}

.label-column {
  flex: 0 0 112px;

  /* flex: 0 0 85px; */
  flex-direction: column;
}

.label-column label {
  /* margin-bottom: 10px; */
  height: 53px; /* Set the desired height for the labels */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-column {
  flex: 0 1 250px;
  display: flex;
  flex-direction: column;
  margin-left: 10px; /* Add left margin to create space */
  max-width: 400px; /* Adjust the max-width as needed */
}

.button-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px; /* Add left margin to create space */
  max-width: 200px; /* Adjust the max-width as needed */
}
.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: fixed;
  z-index: 1051;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: auto;
  margin: 0;
  pointer-events: none;
}

.button-group {
  text-align: center;
}

.button-group button {
  margin: 0 5px;
}

dialog {
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 0.4rem; */

  padding: 0;
  /* width: 450px; */
  position: fixed;
  left: 0;
  top: 50%;

  background-color: #fff;
  z-index: 100;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

.dialog-symitar {
  width: 475px;
  margin-top: -240px;
}

.dialog-fiserve {
  width: 900px;
  margin-top: -330px;

}

/* dialog > .grid-container {
  grid-template-columns: 122px 327px;
} */

.dialog-grid-container > label {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}
.dialog-header {
  margin: 0;
  margin-bottom: 16px;
  font-size: 24px;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -10;
}

.dialog-body {
  z-index: 10;
  background-color: #fff;
  padding: 2rem;
  /* border-radius: 6.4px; */
}

.dialog-symitar .dialog-grid-container {
  display: inline-grid;
  align-items: baseline;
  column-gap: 10px;
  grid-template-columns: 128px 269px;
  grid-template-rows: 60px;
}

.dialog-fiserve .dialog-grid-container {
  display: inline-grid;
  align-items: baseline;
  column-gap: 10px;
  grid-template-columns: 106px 265px;
  grid-template-rows: 60px;
  grid-template-columns: max-content 1fr; 
width: 100%;
}

.dialog-form > .grid-container {
  grid-template-columns: 122px 327px;
}
.button-column button {
  width: 65px;
}

select {
  padding: 8px 2px;
  height: 42px;
  font-size: 1rem;
}
</style>
