<script setup>
</script>
<template>
<div style="display: flex; align-items: baseline; justify-content: center; flex-direction: column; padding: 20px;">
<h3>Thank you for using MemberApp! This privacy policy explains how we collect, use, and disclose your personal information when you use our service. By using our service, you agree to the terms of this privacy policy.</h3>

<h3>What information do we collect?</h3>

<p>We collect personal information that you provide to us when you sign up for an account, use our service, or contact us for support. This information may include your name, email address, phone number, and payment information. We also collect information about your use of our service, such as your IP address, device information, and browsing activity.</p>

<h3>How do we use your information?</h3>

<p>We use your information to provide and improve our service, to communicate with you, to enforce our terms of service, and to comply with legal obligations. We may also use your information to personalize your experience and to show you targeted ads.</p>

<h3>How do we protect your information?</h3>

<p>We take the security of your information seriously and use industry-standard measures to protect it from unauthorized access, disclosure, or use. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>

<h3>Do we share your information?</h3>

<p>We may share your information with third-party service providers who help us to provide our service, such as hosting providers, payment processors, and customer support platforms. We may also share your information with government authorities or law enforcement agencies as required by law or to protect our legal rights.</p>

<h3>Do we use cookies?</h3>

<p>We use cookies and other similar technologies to enhance your experience and to provide and improve our service. You can control cookies through your browser settings, but disabling cookies may limit your ability to use certain features of our service.</p>

<h3>Do we transfer your information internationally?</h3>

<p>Your information may be transferred to and processed in countries outside of the country where you reside, which may have different data protection laws than in your country. By using our service, you consent to the transfer of your information to these countries.</p>

<h3>How can you access or delete your information?</h3>

<p>You can access or delete your personal information by contacting us at [contact email]. We will respond to your request within a reasonable timeframe and may require additional information from you to verify your identity.</p>

<h3>Changes to this privacy policy</h3>

<p>We may update this privacy policy from time to time to reflect changes in our service or legal requirements. We will notify you of any material changes by posting the updated privacy policy on our website or by sending you an email notification.</p>

<h3>Contact us</h3>

<p>If you have any questions or concerns about this privacy policy or our use of your information, please contact us at [contact email].</p>
</div>
</template>

<script>
export default {
  name: "ForgotPasswordChoose",
  data: () => ({}),
  methods: {},
  mounted(){},
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: justify;
}
h1, h2 {
  color: #444;
  text-align: center;
}
h1 {
  margin-top: 40px;
}
h2 {
  margin-top: 30px;
}
p {
  color: #666;
  line-height: 1.5;
  margin: 20px 0;
  text-align: justify;
}
a {
  color: #007bff;
  text-decoration: none;
}
</style>
