
<template>
    <div class="tooltip" v-if="isVisible">
    
      <div class="tooltip-content">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .tooltip {
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2); /* Shadow for the tooltip box */
  color: #000000;
  padding: 8px 12px;
  font-size: 16px;
  white-space: nowrap;
  z-index: 1001;
  transform: translate(-50%, -150%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Arrow */
.tooltip::before {
  content: "";
  position: absolute;
  bottom: -8px; /* Adjust based on tooltip padding and border */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffffff; /* Matches tooltip background color */
  z-index: -1; /* Ensure the arrow is below the tooltip shadow */
}

/* To avoid visible box around the arrow, apply the shadow directly */
.tooltip::after {
  content: "";
  position: absolute;
  bottom: -12px; /* Adjust based on the arrow's size */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid rgba(167, 167, 167, 0.2); /* Shadow color matching tooltip box shadow */
  z-index: -12; /* Ensure the shadow is behind the tooltip */
}

  
  </style>