<script setup>
</script>

<template>
  <div>ERROR 404</div>
</template>

<script>
</script>

<style scoped>
</style>
