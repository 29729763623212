import axios from "axios";

// COMMENTED THIS BLOCK JUST TO RUN THE CORE ACCESS X MAY 24, 2024

// UNCOMMENT THIS ALSO
// const currentURL = window.location.href;
// const baseURL = currentURL.split("#")[0].replace(/\/$/, ""); // Remove trailing slash
// COMMENTED THIS BLOCK JUST TO RUN THE CORE ACCESS X MAY 24, 2024

// UNCOMMENT THIS ALSO
// const currentURL = window.location.href;
// const baseURL = currentURL.split("#")[0].replace(/\/$/, ""); // Remove trailing slash

// Define a mapping of baseURLs to API_BASE_URLs
// const baseURLMappings = {
//   "http://localhost:8080": "http://localhost:443/",
//   "https://demo38web.core-access.com": "https://demo38api.core-access.com",
//   "https://sikorskyweb.core-access.com": "https://sikorskyapi.core-access.com",
//   "https://web1.core-access.com": "https://api1.core-access.com",
// };
// const baseURLMappings = {
//   "http://localhost:8080": "http://localhost:443/",
//   "https://demo38web.core-access.com": "https://demo38api.core-access.com",
//   "https://sikorskyweb.core-access.com": "https://sikorskyapi.core-access.com",
//   "https://web1.core-access.com": "https://api1.core-access.com",
// };

// Set a default API_BASE_URL in case no match is found
// UNCOMMENT THIS SECTION IF YOU WANT TO RUN THE OAUTH PROJECT
// let API_BASE_URL = "https://api1.core-access.com";
// UNCOMMENT THIS SECTION IF YOU WANT TO RUN THE OAUTH PROJECT
// let API_BASE_URL = "https://api1.core-access.com";

// THIS PART IS CORE ACCESS X CODE
let API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
// let API_BASE_URL = 'http://localhost:3000';
// UNCOMMENT THIS SECTION ALSO
// Check if the baseURL matches any of the predefined mappings
// if (baseURL in baseURLMappings) {
//   API_BASE_URL = baseURLMappings[baseURL];
// }

console.log(`client token`, process.env.VUE_APP_CLIENT_TOKEN);

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { token: process.env.VUE_APP_CLIENT_TOKEN },
});

export default axiosInstance;