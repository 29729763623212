// import Home from "./components/HomeComponent.vue";
// import MyProfile from "./components/MyProfile.vue";
import ManageUsers from "./components/ManageUsers.vue";
import HistoryComponent from "./components/HistoryComponent.vue";
import NotesComponent from "./components/NotesComponent.vue";
import MemberInformation from "./components/MemberInformation.vue";
import SecurityInformation from "./components/SecurityInformation.vue";
import ManageBrand from "./components/ManageBrand.vue";
import ManageBrandCreateEdit from "./components/ManageBrandCreateEdit.vue";
import store from "./store/store.js";
import AdminLinkRouter from "./components/AdminLinkRouter.vue";
import ClientAdminDashboard from "./components/AdminDashboard.vue";
import LoginComponent from "./components/LoginComponent.vue";
import ThresholdSettingsComponent from "./components/ThresholdSettingsComponent.vue";
import WhitelistPhoneComponent from "./components/WhitelistPhoneComponent.vue";
import IVRConfigComponent from "./components/IVRConfigComponent.vue";
import NoteCategoryComponent from "./components/NoteCategoryComponent.vue";
import WarningCodesComponent from "./components/WarningCodesComponent.vue";
import CreateEditUser from "./components/CreateEditUser.vue";
import PrivacyPage from "./components/PrivacyPage.vue";
import CompanyConfiguration from "./components/CompanyConfiguration.vue";
import NULLpage from "./components/NULLpage.vue";
// import { useCookies } from 'vue3-cookies';
import api from "./api/api.js";
import NewMyProfile from "./components/NewMyProfile.vue";
// import { component } from "vue/types/umd";

//check if session in storage is match in the db
const getSession = async () => {
  try {
    const passLoginUp = JSON.parse(localStorage.getItem("passLoginUp"));
    const session = localStorage.getItem("session");
    const response = await api.get(`getSessionLogin/${passLoginUp.ind_id}`);
    if (response.data.length >= 1) {
      return response.data[0].session_login == session;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

function clearSession(next) {
  store.state.isUserLogged = false;
  // updateLoggedinStatus()

  // Get the keys from localStorage
  const keys = Object.keys(localStorage);

  // Iterate through the keys and remove items that are not 'companyID' or 'authToken'
  for (const key of keys) {
    if (key !== "companyID" && key !== "authToken") {
      localStorage.removeItem(key);
    }
  }

  next("/login");
}

// update db logged_in status
async function updateSessionLogin(ind_id, session_id) {
  await api.put(`/updateSession/${ind_id}`, {
    session_id: session_id,
    ip_address: null,
  });
}

function checkAuthExpiration(next) {
  const authCookie = new Date(
    localStorage.getItem("authenticate_vue-cookies_expires")
  );

  const expirationDate = authCookie;
  const currentDate = new Date();

  // if no cookie or expired session
  if (!authCookie || currentDate > expirationDate) {
    store.state.isUserLogged = false;
    updateLoggedinStatus();
    next("/login");
    localStorage.clear();
  } else {
    // update expiration date session
    updateCookieExpiration();
  }
}

// update db logged_in status
async function updateLoggedinStatus() {
  const passLoginUp = JSON.parse(localStorage.getItem("passLoginUp"));

  await api.put("/auths/lastlogout", {
    ind_id: passLoginUp.ind_id,
  });

  updateSessionLogin(passLoginUp.ind_id, null);
}

// update cookie expiration in local storage when the user still using the system
function updateCookieExpiration() {
  let expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 24);
  // expirationDate.setSeconds(expirationDate.getSeconds() + 10)
  let localExpirationDate = expirationDate.toLocaleString("en-US", {
    timeZone: "Asia/Hong_Kong",
  });
  localStorage.setItem("authenticate_vue-cookies_expires", localExpirationDate);
}

// to be deleted
// async function checkAuthExpiration_old(next) {
//   const { cookies } = useCookies();

//   //const expirationDate = new Date(cookies.get('authenticate_vue-cookies_expires'));
//   const expirationDate = new Date(localStorage.getItem('authenticate_vue-cookies_expires'));
//   const currentDate = new Date();
//   let isLoggedIn = localStorage.getItem('authenticate');
//   if (expirationDate && expirationDate < currentDate) {
//     console.log('IF');
//     isLoggedIn = false
//     try {
//       const passLoginUp = JSON.parse(localStorage.getItem('passLoginUp'))
//       console.log('route.js :', JSON.parse(passLoginUp))
//       const res =  api.put('/auths/lastlogout', {
//         ind_id: passLoginUp.ind_id
//       })
//       if(res.status === 200){
//         console.log(`${passLoginUp.email} successfully logged out`)
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   } else {
//     // updateCookieExpiration()
//     console.log('ELSE');
//     isLoggedIn = true;
//     store.getters.removeAuthString
//   }
//   if(localStorage.getItem('authenticate') != 'true')
//   {
//     alert(
//       'You do not have access to this function. Please Login first.'
//     )
//     next('/login')
//   }
//   localStorage.setItem('authenticate', JSON.stringify(isLoggedIn));

//   console.log('Cookie value isLoggedin:', cookies.get('authenticate_vue-cookies'));
//   console.log('Cookie value ExpirationDate:', cookies.get('authenticate_vue-cookies_expires'));
//   console.log('localStorage.getItem(authenticate) isLoggedin:', localStorage.getItem('authenticate'));
//   console.log('localStorage.getItem(authenticate_vue-cookies_expires)', localStorage.getItem('authenticate_vue-cookies_expires'));
//   console.log(localStorage.getItem('authenticate'));
//   console.log(typeof localStorage.getItem('authenticate'));
//   console.log('Expiration Date:', expirationDate);
//   console.log('Current Date:', currentDate);
// }

const authStringFinder = [
  {
    my_profile: null,
    history_component: null,
    notes_component: null,
    member_information: null,
    security_information: null,
    CreateEditUser: null,
  },
];
const routes = [
  {
    path: "/",
    component: LoginComponent,
    beforeEnter: (to, from, next) => {
      const isLoggedIn = localStorage.getItem("authenticate");
      const passLoginUp = localStorage.getItem("passLoginUp");
      const passLoginUpData = passLoginUp ? JSON.parse(passLoginUp) : null;
      let home_screen = "/";
      if (isLoggedIn === "true") {
        home_screen = passLoginUpData.home_screen;
      }
      console.log("isloggedin", isLoggedIn); //true
      console.log(typeof isLoggedIn);
      if (isLoggedIn === "true") {
        // User is already logged in, redirect to the previous page
        next(home_screen);
        console.log("from", home_screen);
      } else {
        // User is not logged in, proceed to the login page

        console.log();
        next("/login");
        console.log("login");
      }
    },
  },
  {
    path: "/admin_dashboard",
    name: "/AdminDashboardClientAdminViewer",
    component: ClientAdminDashboard,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.brandscreen =
              store.getters.getAuthString[0].toString().substring(8, 9) == "1";
            if (authStringFinder.brandscreen) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/manage-brand",
    name: "manage-brand",
    component: ManageBrand,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.brandscreen =
              store.getters.getAuthString[0].toString().substring(13, 14) ==
              "1";
            if (authStringFinder.brandscreen) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/manage-brand-create-edit",
    name: "manage-brand-create-edit",
    component: ManageBrandCreateEdit,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.brandcreateedit =
              store.getters.getAuthString[0].toString().substring(13, 14) ==
              "1";

            console.log(
              "authStringFinder.brandcreateedit",
              authStringFinder.brandcreateedit
            );
            if (authStringFinder.brandcreateedit) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access.1111111111"
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/my_profile",
    name: "my_profile",
    // component: MyProfile,
    component: NewMyProfile,

    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          console.log("my profile if");
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.my_profile =
              store.getters.getAuthString[0].toString().substring(0, 1) == "1";
            if (authStringFinder.my_profile) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          console.log("my profile else");
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/member_information", // past name is = user-details
    name: "MemberInformation",
    component: MemberInformation,
    beforeEnter: (to, from, next) => {
      // Access the AuthToken value from the route
      const authToken = to.query.AuthToken;
      // console.log("jomel authToken", authToken);
if(!localStorage.getItem("authToken")){
// Store the authToken in localStorage
localStorage.setItem("authToken", authToken);
}
    
      // console.log("jomel stored authToken", localStorage.getItem("authToken"));
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
        // console.log(
        //     "jomel stored authToken",
        //     localStorage.getItem("authToken")
        //   );
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
            console.log(
              "store.getters.getAuthString[0]",
              store.getters.getAuthString[0]
            );
          } else {
            authStringFinder.member_information =
              store.getters.getAuthString[0].toString().substring(1, 2) == "1";
            if (authStringFinder.member_information) {
              next();
              // console.log(
              //   "store.getters.getAuthString[0]",
              //   store.getters.getAuthString[0]
              // );
            } else {
              // console.log(
              //   "store.getters.getAuthString[0]",
              //   store.getters.getAuthString[0]
              // );
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/login");
            }
          }
        } else {
          clearSession(next);
          // Store the authToken in localStorage
          localStorage.setItem("authToken", authToken);
        }
      })();
    },
  },
  {
    path: "/history", // past name is = user-details
    name: "HistoryComponent",
    component: HistoryComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.history_component =
              store.getters.getAuthString[0].toString().substring(2, 3) == "1";
            if (authStringFinder.history_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/security_information", // past name is = user-details
    name: "SecurityInformation",
    component: SecurityInformation,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.security_information =
              store.getters.getAuthString[0].toString().substring(3, 4) == "1";
            if (authStringFinder.security_information) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/notes", // past name is = user-details
    name: "NotesComponent",
    component: NotesComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(4, 5) == "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/manage_user", // past name is find-user
    name: "manage_user",
    component: ManageUsers,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.manage_user =
              store.getters.getAuthString[0].toString().substring(5, 6) == "1"; // fix disable on coach
            if (authStringFinder.manage_user) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/threshold_settings",
    name: "ThresholdSettingsComponent",
    component: ThresholdSettingsComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(9, 10) == "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/whitelist_phone",
    name: "WhitelistPhoneComponent",
    component: WhitelistPhoneComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(10, 11) ==
              "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/ivr_config",
    name: "IVRConfigComponent",
    component: IVRConfigComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(11, 12) ==
              "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/warning_codes",
    name: "WarningCodesComponent",
    component: WarningCodesComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(12, 13) ==
              "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/note_category",
    name: "NoteCategoryComponent",
    component: NoteCategoryComponent,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.notes_component =
              store.getters.getAuthString[0].toString().substring(14, 15) ==
              "1";
            if (authStringFinder.notes_component) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/create-edit-user",
    name: "CreateEditUserBox",
    props: true,
    component: CreateEditUser,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.CreateEditUserBox =
              store.getters.getAuthString[0].toString().substring(5, 6) == "1";
            if (authStringFinder.CreateEditUserBox) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  {
    path: "/company-configuration",
    name: "CompanyConfiguration",
    props: true,
    component: CompanyConfiguration,
    beforeEnter: (to, from, next) => {
      (async () => {
        const isMatched = await getSession(); //check if session match in the db
        if (isMatched) {
          checkAuthExpiration(next);
          if (store.getters.getAuthString[0] == null) {
            alert(
              "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
            );
            next("/login");
          } else {
            authStringFinder.CreateEditUserBox =
              store.getters.getAuthString[0].toString().substring(5, 6) == "1";
            if (authStringFinder.CreateEditUserBox) {
              next();
            } else {
              alert(
                "You do not have access to this function. Please raise a Help Desk ticket here <help desk email link> if you need access."
              );
              next("/");
            }
          }
        } else {
          clearSession(next);
        }
      })();
    },
  },
  { path: "/admin-link-router", component: AdminLinkRouter },
  { path: "/privacy_page", component: PrivacyPage },
  { path: "/404_page", component: NULLpage },
  {
    path: "/login",
    component: LoginComponent,
    beforeEnter: (to, from, next) => {
      const isLoggedIn = localStorage.getItem("authenticate");
      if (isLoggedIn === "true") {
        // User is already logged in, redirect to the previous page
        next(from + "?AuthToken=" + localStorage.getItem("authToken"));
      } else {
        // Continue to the login page
        next();
      }
    },
  },
];

export default routes;
