<script setup>
import api from "../api/api";
import ButtonSubmit from "./ButtonSubmit.vue";
import moment from "moment-timezone";
import { readDate } from '../utils/timezone.js';

</script>

<template>
  <ButtonSubmit 
    label="Refresh"
    type="submit"
    style="margin-bottom: 10px;"
    @click="getData()"
  />

  <div v-if="isLoading">Loading...</div> <!-- Loading indicator -->

  <table id="table">
    <thead>
      <tr>
        <th
          :style="{ backgroundColor: brandData.main_color2 }"
          v-for="head in table_headers"
          :key="head"
        >
          <a
            @click="sortByHeader(head, individualData)"
            style="
              display: flex;
              flex-direction: row;
              cursor: pointer;
            "
          >
            {{
              head == 'organization'
                ? 'Organization'
                : head == 'email'
                ? 'Email'
                : head == 'logged_in'
                ? 'Logged-In'
                : head == 'startup_screen'
                ? 'Startup Screen'
                : head == 'first_name'
                ? 'First Name'
                : head == 'last_name'
                ? 'Last Name'
                : head == 'roles'
                ? 'Roles'
                : head == 'added'
                ? 'Added'
                : head == 'last_login'
                ? 'Last Login'
                : null
            }}
            <div class="sort-icon" :class="{ active: sortColumn === head }">
              <a v-if="sortDirection === 'asc' && sortColumn === head">
                <i class="fa-solid fa-sort-down"></i>
              </a>
              <a v-else-if="sortDirection === 'desc' && sortColumn === head">
                <i class="fa-solid fa-sort-up"></i>
              </a>
            </div>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="input-container">
            <input
            type="text"
            placeholder="org..."
            v-model="searchOrg"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="textarea"
              placeholder="email..."
              style=""
              v-model="searchEmail"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="logged-in..."
              style="min-width: 10px"
              v-model="searchLoggedIn"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="startup screen..."
              style=""
              v-model="searchStartupScreen"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="first name..."
              style=""
              v-model="searchFirstName"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="last name..."
              style=""
              v-model="searchLastName"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              type="text"
              placeholder="roles..."
              style=""
              v-model="searchRoles"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              class="input1"
              type="datetime-local"
              id="AddedDate"
              style=""
              v-model="searchAdded"
            />
          </div>
        </td>
        <td>
          <div class="input-container">
            <input
              class="input2"
              type="datetime-local"
              id="LastLoginDate"
              style=""
              v-model="searchLastLogin"
            />
          </div>
        </td>
      </tr>
      <tr v-for="(item, index) in filteredAdminDashboard" :key="index">
        <td>{{ item.organization }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.logged_in }}</td>
        <td>{{ item.startup_screen }}</td>
        <td>{{ item.first_name }}</td>
        <td>{{ item.last_name }}</td>
        <td>{{ item.roles }}</td>
        <td>{{ readDate(item.added, parsedLoginUp.time_zone) }}</td>
        <td>{{ readDate(item.last_login, parsedLoginUp.time_zone) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AdminDashboardBox",
  components: [ButtonSubmit],
  props: ["buttonLabel", "userData", "brandData"],
  data: () => ({
    table_headers: [
      "organization",
      "email",
      "logged_in",
      "startup_screen",
      "first_name",
      "last_name",
      "roles",
      "added",
      "last_login"
    ],
    individualData: [],
    sortDirection: 'asc',
    sortColumn: '',

    searchOrg: '',
    searchEmail: '',
    searchLoggedIn: '',
    searchStartupScreen: '',
    searchFirstName: '',
    searchLastName: '',
    searchRoles: '',
    searchAdded: '',
    searchLastLogin: '',

    localStorageLoginUp: localStorage.getItem("passLoginUp"),
    parsedLoginUp: null,

    isLoading: false,
  }),
  mounted() {
    try {
        this.parsedLoginUp = JSON.parse(this.localStorageLoginUp);
      } catch (error) {
        console.error("Failed to parse localStorageLoginUp:", error);
      }
    this.lastLoginChecker();
    this.getData();
  },
  methods: {
      async lastLoginChecker(){
        try {
          const res = await api.put(`/lastLoginChecker`);
          if (res.status === 200) {
            console.log("success last login updated");
          }
        } catch (error) {
          console.log(error);
        }
      },
      async getData() {
    console.log('Refreshing system...'); // Log for debugging

    try {
      // Clear the existing data to indicate that a refresh is in progress
      this.individualData = [];
      this.isLoading = true; // Assume you have an `isLoading` data property for showing a loading indicator

      // Fetch new data from the API
      const res = await api.get(`/individualsNEW/${this.userData.org_id}`);
      if (res.status === 200) {
        this.individualData = res.data;
        console.log('Data refreshed successfully:', this.individualData);
      } else {
        console.error('Failed to refresh data:', res.status, res.statusText);
      }
    } catch (error) {
      console.error('Error occurred while refreshing data:', error);
    } finally {
      this.isLoading = false; // Hide the loading indicator
    }
  },

    sortByHeader(header, arr) {
      if (this.sortColumn === header) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = header;
        this.sortDirection = 'asc';
      }

      arr.sort((a, b) => {
        if (a[header] < b[header]) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (a[header] > b[header]) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
  },
  computed: {
     // Inside your computed property filteredAdminDashboard
    filteredAdminDashboard() {
      if (!this.individualData) {
        return [];
      }

      // Convert searchAdded and searchLastLogin to Moment.js objects using readDate
      const searchAdded = this.searchAdded ? readDate(this.searchAdded, this.parsedLoginUp.time_zone) : null;
      const searchLastLogin = this.searchLastLogin ? readDate(this.searchLastLogin, this.parsedLoginUp.time_zone) : null;

      return this.individualData.filter(item => {
        const addedDate = item.added ? readDate(item.added, this.parsedLoginUp.time_zone) : null;
        const lastLoginDate = item.last_login ? readDate(item.last_login, this.parsedLoginUp.time_zone) : null;

        return (
          (this.searchOrg === "" || (item.organization?.toLowerCase() ?? '').includes(this.searchOrg)) &&
          (this.searchEmail === "" || (item.email?.toLowerCase() ?? '').includes(this.searchEmail)) &&
          (this.searchLoggedIn === "" || (item.logged_in?.toLowerCase() ?? '').includes(this.searchLoggedIn)) &&
          (this.searchStartupScreen === "" || (item.startup_screen?.toLowerCase() ?? '').includes(this.searchStartupScreen)) &&
          (this.searchFirstName === "" || (item.first_name?.toLowerCase() ?? '').includes(this.searchFirstName)) &&
          (this.searchLastName === "" || (item.last_name?.toLowerCase() ?? '').includes(this.searchLastName)) &&
          (this.searchRoles === "" || (item.roles?.toLowerCase() ?? '').includes(this.searchRoles)) &&
          (searchAdded === null || (addedDate && moment(addedDate).isSameOrAfter(searchAdded))) &&
          (searchLastLogin === null || (lastLoginDate && moment(lastLoginDate).isSameOrAfter(searchLastLogin)))
        );
      });
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
* {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
}
@media only screen and (min-width: 280px) and (max-width: 900px) {
  .body-form-container {
    font-size: 2.5vw;
    background: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
  }
  #table {
    font-size: 2vw;
    border-collapse: collapse;
    word-break: break-word;
  }
}
#table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border: 1px solid black;
}

#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  font-weight: 400;
}

#table td {
  text-align: left;
  vertical-align: middle;
}
#table td input {
  width: 100%;
  box-sizing: border-box; /* include padding and border in width */
}
.sort-icon {
  margin-left: 10px;
}
.input-container input {
  font-size: 16px;
}
</style>
