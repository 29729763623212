<script setup>
import ManageBrandCreateEditBox from "./ManageBrandCreateEditBox.vue"
</script>

<template>
  <div class="body-form-container">
    <div style="">
      <ManageBrandCreateEditBox :userData="userData" :brandData="brandData" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageBrandCreateEdit",
  component: [ManageBrandCreateEditBox],
  data: () => {},
  props: {
    userData: Object,
    brandData: Object,
  },
}
</script>

<style scoped>
.description {
  font: -webkit-control;
}

.body-form-container {
  margin: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
