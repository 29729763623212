<script setup>
import Header from "./Header.vue"
import BaseInput from "./UI/BaseInput.vue"
import BaseButton from "./UI/BaseButton.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import api from "../api/api.js"
</script>

<template>
  <div class="body-container">
    <header class="">
      <h1>Warning Codes</h1>
      <h1>WC01</h1>
    </header>
    <div class="row d-flex space-between">
      <form action="" @submit.prevent="findCodes()">
        <label for="" class="">Warning Description </label>
        <BaseInput type="text" v-model="searchCode" />
        <ButtonSubmit type="submit" label="Search" />
        <!-- <input type="text" v-model="searchCode" class="form-control" /> -->
        <!-- <button type="submit" class="btn-search-code btn-submit">Search</button> -->
      </form>
    </div>

    <div class="row">
      <table id="table" @click="getDataRow($event)">
        <thead>
          <tr>
            <th style="width: 100px">Warning Code</th>
            <th>Warning Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="code in wCodes" :key="code.id" :id="code.id">
            <td name="warningcode">{{ code.WarningNumber }}</td>
            <td name="description">{{ code.WarnDescription }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <!--  <div class="pagination">
                <div>
                    <p>{{ wCodes.length }} results found</p>
                </div>
                <div class="d-flex">
                    <div>
                        <button type="button" :disabled="this.page == 1" @click="page--">Previous</button>
                    </div>
                    <div
                        v-for="pageNumber in pageLength ? pages.slice(page - 1, page + 5) : pages"
                        :key="pageNumber"
                        @click="page = pageNumber">
                        <button style="background-color: #0e5071; color: white" v-if="pageNumber == page">
                            {{ pageNumber }}
                        </button>
                        <button v-else>{{ pageNumber }}</button>
                    </div>

                    <div>
                        <button type="button" @click="page++" :disabled="this.page >= this.pages.length">Next</button>
                    </div>
                </div>
            </div> -->
    </div>
    <!-- place a form element here -->
    <div class="row">
      <!-- <input type="text" v-model="newCodeObj.warningcode" class="" placeholder="Warning Code" />
            <input type="text" v-model="newCodeObj.description" class="" placeholder="Warning Description" /> -->
    </div>
    <div class="row">
      <!-- <button type="submit" @click="storeWarningCode()" class="btn btn-primary" ref="saveBtn" disabled>
                SAVE
            </button>
            <button type="button" @click="setNewCode()" class="btn btn-success">New</button>
            <button type="submit" @click="deleteWarningCode()" class="btn btn-danger" :disabled="!isDelete">
                Delete
            </button> -->
    </div>
  </div>
</template>

<script>
// import axios from "axios"

// const apiClient = axios.create({
//   headers: {
//     Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
//     "Content-Type": "application/json",
//   },
// })
export default {
  name: "WarningCodesComponent",
  component: [ Header, BaseInput, BaseButton],
  data: () => {
    return {
      id: "",
      warningCodes: [],
      wCodes: [],
      filterCodes: [],
      company: "1286",
      searchCode: "",
      perPage: 10,
      pages: [],
      page: 1,
      isNew: false,
      isEdit: false,
      isDelete: false,
    }
  },
  props: {
    userData: Object,
  },
  async mounted() {
    this.getWarningCodes()
  },
  computed: {
    pageLength() {
      return this.pages.length > 5
    },
    codes() {
      return this.paginate(this.wCodes)
    },
  },
  methods: {
    findCodes() {
      this.wCodes = this.filterCodes.filter((item) => {
        if (!this.searchCode) return true
        if (item.WarnDescription.toLowerCase().match(this.searchCode)) {
          return true
        }
      })
      console.log(this.wCodes)
      // this.setPages(this.wCodes)
    },
    setPages(codeList) {
      this.pages = []
      this.page = 1
      let numberOfPages = Math.ceil(codeList.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(codeList) {
      const from = this.page * this.perPage - this.perPage
      const to = this.page * this.perPage
      return codeList.slice(from, to)
    },
    // async getWarningCodes() {
    //   try {
    //     const { data } = await apiClient.get(
    //       "https://genesys-api.gcm3.com/apiNODE/GetWarningCodesAll.php"
    //     )
    //     this.wCodes = data.Warnings
    //     this.filterCodes = data.Warnings
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    async getWarningCodes() {
      try {
        const { data } = await api.post(
          "GetWarningCodesAll"
        )
        this.wCodes = data.Warnings
        this.filterCodes = data.Warnings
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 24px;
}

label {
  /*    margin: 0 10px;*/
  margin: 0 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  grid-column: 1/5;
}

input {
  padding: 5px;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #767676;
  width: 250px;
  /*    outline: none;*/
}

button {
  margin: 0 10px;
}

th {
  text-align: center !important;
}

tr td:first-of-type {
  text-align: right;
}

form {
  display: flex;
  align-items: center;
}
.body-container {
  align-self: unset;
  /*    height: 100vh;*/
}

#table {
  width: 40% !important;
}

.row {
  margin: 20px;
}

.row > input {
  margin: 0 10px;
}

.row > input:nth-of-type(2) {
  width: 250px;
}

.p-5 {
  padding: 6px;
}

.pagination > p {
  font-size: 14px;
}

.space-between {
  justify-content: space-between;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-left: 3px;
  margin-right: 3px;
}

.btn-search-code {
  margin-left: 10px;
  vertical-align: top;
}

.btn {
  margin: 0 5px;
  padding: 7px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  width: 70px;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

button:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

/*.row-flex {
    display: flex;
    justify-content: space-between;
}*/
</style>
