<script setup>
import WhitelistPhoneBoxComponent from "./WhitelistPhoneBoxComponent.vue"
import Header from "./Header.vue"
</script>

<template>
  <div class="body-container">
    <WhitelistPhoneBoxComponent :userData="userData" />
  </div>
</template>

<script>
export default {
  name: "WhitelistPhoneBoxComponent",
  component: [WhitelistPhoneBoxComponent, Header],
  data: () => {},
  props: {
    userData: Object,
  },
}
</script>
<style scoped>
.body-container {
  margin: 20px 0;
  width: 95%;
}
</style>
