<template>
  <button><slot /></button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    label: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
button {
  border: 1px;
  box-shadow: 2px 4px #ccc;
  height: 35px;
  border-radius: 20px;
}
</style>
