<script setup></script>

<template>
  <div class="wrapper">
    <div class="sidenav">
      <ul>
        <!-- <li>
          <router-link @click.prevent="menuClicked()" to="/">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-house"></i>
            </div>
            Home
          </router-link>
        </li> -->

        <li v-if="this.SidenavItems.MyProfile">
          <router-link @click.prevent="menuClicked()" to="/my_profile">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-address-card"></i>
            </div>
            My Profile
          </router-link>
        </li>

        <li v-if="this.SidenavItems.MemberInformation">
          <router-link @click.prevent="menuClicked()" to="/member_information">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Member Information
          </router-link>
        </li>

        <li v-if="this.SidenavItems.History">
          <router-link @click.prevent="menuClicked()" to="/history">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            History
          </router-link>
        </li>

        <li v-if="this.SidenavItems.SecurityInformation">
          <router-link @click.prevent="menuClicked()" to="/security_information">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Security Information
          </router-link>
        </li>

        <li v-if="this.SidenavItems.Notes">
          <router-link @click.prevent="menuClicked()" to="/notes">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Notes
          </router-link>
        </li>

        <li v-if="this.SidenavItems.ManageUser">
          <router-link @click.prevent="menuClicked()" to="/manage_user">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-user-group"></i>
            </div>
            Manage Users
          </router-link>
        </li>

        <!-- <li v-if="this.SidenavItems.UploadManyUsersAtOnce">
          <router-link @click.prevent="menuClicked()" to="/upload_many_users_at_once">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-people-group"></i>
            </div>
            Upload Bulk Users
          </router-link>
        </li>

        <li v-if="this.SidenavItems.ViewElevatedUsers">
          <router-link @click.prevent="menuClicked()" to="/elevated-users">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-arrows-down-to-people"></i>
            </div>
            View Elevated Users
          </router-link>
        </li> -->

        <li v-if="this.SidenavItems.ClientAdminDashboard">
          <router-link @click.prevent="menuClicked()" to="/admin_dashboard">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Admin Dashboard
          </router-link>
        </li>

        <li v-if="this.SidenavItems.ThresholdSettings">
          <router-link @click.prevent="menuClicked()" to="/threshold_settings">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-people-group"></i>
            </div>
            Threshold Settings
          </router-link>
        </li>

        <li v-if="this.SidenavItems.WhitelistPhone">
          <router-link @click.prevent="menuClicked()" to="/whitelist_phone">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-envelope"></i>
            </div>
            Whitelist Phone
          </router-link>
        </li>

        <li v-if="this.SidenavItems.IVRConfig">
          <router-link @click.prevent="menuClicked()" to="/ivr_config">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-credit-card"></i>
            </div>
            IVR Config
          </router-link>
        </li>

        <li v-if="this.SidenavItems.WarningCodes">
          <router-link @click.prevent="menuClicked()" to="/warning_codes">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Warning Codes
          </router-link>
        </li>

        <li v-if="this.SidenavItems.NoteCategory">
          <router-link @click.prevent="menuClicked()" to="/note_category">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-clipboard-list"></i>
            </div>
            Note Category
          </router-link>
        </li>

        <li v-if="this.SidenavItems.BrandScreen">
          <router-link @click.prevent="menuClicked()" to="/manage-brand">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-building"></i>
            </div>
            Manage Brand
          </router-link>
        </li>
        <li v-if="this.SidenavItems.CompanyConfiguration">
          <router-link @click.prevent="menuClicked()" to="/company-configuration">
            <div class="icon_container">
              <i class="icon-color-sidebar fa-solid fa-credit-card"></i>
            </div>
            Company Configuration
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "../store/store.js"
export default {
  data: () => ({
    SidenavItems: {
      MyProfile: false,
      MemberInformation: false,
      History: false,
      SecurityInformation: false,
      Notes: false,
      ManageUser: false,
      UploadManyUsersAtOnce: false,
      ViewElevatedUsers: false,
      ClientAdminDashboard: false,
      ThresholdSettings: false,
      WhitelistPhone: false,
      IVRConfig: false,
      WarningCodes: false,
      BrandScreen: false,
      NoteCategory: false,
      CompanyConfiguration: false,
    },
    isClicked: true,
  }),
  props: {},
  methods: {
    menuClicked() {
      this.isClicked = false
      this.$emit("navbarclick", this.isClicked)
    },
  },
  mounted() {
    const authString = store.getters.getAuthString
    this.SidenavItems.MyProfile = authString[0].toString().substring(0, 1) == "1"
    this.SidenavItems.MemberInformation = authString[0].toString().substring(1, 2) == "1"
    this.SidenavItems.History = authString[0].toString().substring(2, 3) == "1"
    this.SidenavItems.SecurityInformation = authString[0].toString().substring(3, 4) == "1"
    this.SidenavItems.Notes = authString[0].toString().substring(4, 5) == "1"
    this.SidenavItems.ManageUser = authString[0].toString().substring(5, 6) == "1"
    this.SidenavItems.UploadManyUsersAtOnce = authString[0].toString().substring(6, 7) == "1"
    this.SidenavItems.ViewElevatedUsers = authString[0].toString().substring(7, 8) == "1"
    this.SidenavItems.ClientAdminDashboard = authString[0].toString().substring(8, 9) == "1"
    this.SidenavItems.ThresholdSettings = authString[0].toString().substring(9, 10) == "1"
    this.SidenavItems.WhitelistPhone = authString[0].toString().substring(10, 11) == "1"
    this.SidenavItems.IVRConfig = authString[0].toString().substring(11, 12) == "1"
    this.SidenavItems.WarningCodes = authString[0].toString().substring(12, 13) == "1"
    this.SidenavItems.BrandScreen = authString[0].toString().substring(13, 14) == "1"
    this.SidenavItems.NoteCategory = authString[0].toString().substring(15, 16) == "1"
    this.SidenavItems.CompanyConfiguration = authString[0].toString().substring(16, 17) == "1"
  },
}
</script>

<style scoped>
* {
  padding: 0px;
  list-style: none;
  text-decoration: none;
  font-family: "Arial";
  transition: all 150ms ease;
  font-weight: bold;
}

.wrapper {
  height: 100%;
}

.wrapper .sidenav {
  width: 240px;
  box-shadow: 10px -5px 20px -0.7px rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-color: #54575b20;
  border-width: 1px;
  background-color: white;
  height: inherit;
  position: absolute;
  z-index: 99;
}

ul {
  padding-top: 5px;
}

.wrapper .sidenav ul li {
  padding: 4px;
}

.wrapper .sidenav ul li a {
  color: #252525af;
  display: block;
}

.wrapper .sidenav ul li a .icon_container {
  padding-bottom: 1px;
  color: #252525af;
  float: left;
  width: 34px;
  text-align: center;
  font-size: 15.5px;
}

.wrapper .sidenav ul li:hover {
  background-color: #54575b20;
}

.wrapper .sidenav ul li:hover a {
  color: #252525af;
}
</style>
