<script setup>
import CreateEditUserBox from "./CreateEditUserBox.vue";
</script>

<template>
  <div
    style="
      display: flex;
    "
  >
    <CreateEditUserBox buttonLabel="Create User" :userData="userData" :brandData="brandData"/>
  </div>
</template>

<script>
export default {
  component: [CreateEditUserBox],
  name: "CreateEditUser",
  props: ['userData','brandData'],
  data: () => ({}),
  async mounted(){
  }
};
</script>

<style scoped></style>
