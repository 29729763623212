import { createStore } from 'vuex'

const store = createStore({
  state: {
    authString: [],
    isUserLogged: localStorage.getItem("authenticate") || false
  },
  mutations: {
    addAuthString: function(state, payload) {
      if (Array.isArray(payload)) {
        state.authString = payload;
      } else {
        state.authString = [payload]; // Wrap the string in an array
      }
      localStorage.setItem('authString', JSON.stringify(state.authString));
    },
    setAuthStringFromStorage: function(state) {
      const storedAuthString = localStorage.getItem('authString');
      if (storedAuthString) {
        if (Array.isArray(state.authString)) {
          state.authString = JSON.parse(storedAuthString);
        } else {
          state.authString = [storedAuthString]; // Wrap the string in an array
        }
      }
    }
  },
  getters: {
    getAuthString: function(state) {
      return state.authString;
    }
  },
  actions: {
    initializeStore: function({ commit }) {
      commit('setAuthStringFromStorage');
    }
  }
})

store.dispatch('initializeStore');

export default store;
