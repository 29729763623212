<script setup>
import Header from "./Header.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
import ButtonDelete from "./UI/ButtonDelete.vue"
import BaseButton from "./UI/BaseButton.vue"
import BaseInput from "./UI/BaseInput.vue"
import { flashMessage } from "@/functions"
import Swal from "sweetalert2"
import api from "../api/api.js"
</script>

<template>
  <div class="body-container">
    <header>
      <h2>Note Category</h2>
      <h2>C01</h2>
    </header>
    <form action="" class="new-note-categ-form" @submit.prevent="addNoteCategory">
      <!-- <button type="button" :class="newClassObj" @click="isNew = true" :disabled="isNew">New</button> -->
      <!-- <input type="text" class="new-note-categ-input" v-model="noteCategory" required /> -->
      <!-- <button type="submit" :class="saveClassObj" :disabled="!isNew">Save</button> -->
      <!-- <button type="button" class="btn btn-secondary" @click="clearNote" :disabled="!isNew">Cancel</button> -->
      <ButtonSubmit type="button" @click="isNew = true" label="New" :disabled="isNew" />
      <BaseInput type="text" class="new-note-categ-input" v-model="noteCategory" required />
      <ButtonSubmit type="submit" @click="isNew = true" label="Save" :disabled="!isNew" />
      <ButtonCancel type="button" @click="clearNote" label="Cancel" :disabled="!isNew" />
    </form>

    <table id="table" class="note-category-table">
      <thead>
        <tr>
          <th>Num</th>
          <th style="width: 50%">Note Category</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="(data, index) in noteCategoryData" :key="data.IndexNum">
          <td style="text-align: right">{{ index + 1 }}</td>
          <td>
            <BaseInput
              type="text"
              :id="data.IndexNum"
              class="update-note-categ-input"
              :modelValue="data.NoteCategory"
              readonly />
          </td>
          <td class="">
            <div class="update-btn-td">
              <ButtonSubmit
                type="button"
                label="Edit"
                :data-index="data.IndexNum"
                @click="editBtn" />
              <ButtonSubmit
                type="button"
                label="Save"
                :data-index="data.IndexNum"
                class=""
                @click="updateBtn"
                disabled />
              <ButtonCancel
                type="button"
                label="Cancel"
                :data-index="data.IndexNum"
                @click="cancelBtn"
                disabled />
              <ButtonDelete
                type="button"
                label="Delete"
                :data-index="data.IndexNum"
                @click="deleteNoteCategory" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import axios from "axios"

// const api = axios.create({
//   baseURL: "https://genesys-api.gcm3.com/apiNODE/api_notescategory.php",
//   headers: {
//     Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
//     "Content-Type": "application/json",
//   },
// })

export default {
  name: "NoteCategoryComponent",
  component: [Header, BaseButton, BaseInput, ButtonCancel, ButtonSubmit, ButtonDelete],
  props: ["brandData"],
  data: () => {
    return {
      company: localStorage.getItem('companyID'),
      noteCategory: "",
      isNew: false,
      noteCategoryData: {},
    }
  },
  // props: {
  //   userData: Object,
  // },
  mounted() {
    this.getNoteCategory()
    console.log(this.brandData)
  },
  computed: {
    saveClassObj() {
      return {
        btn: true,
        "btn-secondary": !this.isNew,
        "btn-submit": this.isNew,
      }
    },
    newClassObj() {
      return {
        btn: true,
        "btn-secondary": this.isNew,
        "btn-submit": !this.isNew,
      }
    },
  },
  methods: {
    clearNote() {
      this.isNew = false
      this.noteCategory = ""
    },
    editBtn(e) {
      e.target.disabled = true
      e.target.classList.add("btn-secondary")
      e.target.classList.remove("btn-submit")
      e.target.parentNode.childNodes[1].disabled = false
      e.target.parentNode.childNodes[1].classList.add("btn-submit")
      e.target.parentNode.childNodes[1].classList.remove("btn-secondary")
      e.target.parentNode.childNodes[2].disabled = false
      let indexNum = document.getElementById(e.target.dataset.index)
      indexNum.readOnly = false
    },

    cancelBtn(e) {
      e.target.disabled = true
      e.target.parentNode.childNodes[0].disabled = false
      e.target.parentNode.childNodes[0].classList.add("btn-submit")
      e.target.parentNode.childNodes[0].classList.remove("btn-secondary")
      e.target.parentNode.childNodes[1].classList.remove("btn-submit")
      e.target.parentNode.childNodes[1].classList.add("btn-secondary")
      e.target.parentNode.childNodes[1].disabled = true
      const noteObj = this.noteCategoryData.find(
        (index) => index.IndexNum == e.target.dataset.index
      )
      this.getNoteCategory().then((result) => {
        console.log(result)
        if (result) {
          document.getElementById(e.target.dataset.index).value = noteObj.NoteCategory
          document.getElementById(e.target.dataset.index).readOnly = true
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Note Category canceled successfully."
          )
        }
      })
    },

    async updateBtn(e) {
      let indexNum = document.getElementById(e.target.dataset.index)
      try {
        const { data } = await api.post("updateNoteCategory", {
          NoteCategory: indexNum.value,
          IndexNum: e.target.dataset.index,
        })
        if (data.affectedRows == 1) {
          indexNum.readOnly = true
          e.target.disabled = true
          e.target.classList.add("btn-secondary")
          e.target.classList.remove("btn-submit")
          e.target.parentNode.childNodes[0].disabled = false
          e.target.parentNode.childNodes[0].classList.add("btn-submit")
          e.target.parentNode.childNodes[0].classList.remove("btn-secondary")
          e.target.parentNode.childNodes[2].disabled = true
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Note Category updated successfully."
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteNoteCategory(e) {
      let indexNum = document.getElementById(e.target.dataset.index)
      Swal.fire({
        title: "",
        html: `<p>Are you sure you want to delete the note category: <b>${indexNum.value}</b></p>`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          api.post("deleteNoteCategory", { indexNum: e.target.dataset.index }).then((result) => {
            console.log(result)
            if (result.data.affectedRows == 1) {
              this.getNoteCategory()

              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
                this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
                "Note Category deleted successfully."
              )
            }
          })
        }
      })
    },
    async getNoteCategory() {
      try {
        const { data, status } = await api.post("getNoteCategory", { Company: this.company })

        this.noteCategoryData = Object.values(data).sort((a, b) => {
          return new Date(b.ModDateTime) - new Date(a.ModDateTime)
        })

        if (status == 200) {
          return true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async addNoteCategory() {
      try {
        const { data } = await api.post("addNoteCategory", {
          NoteCategory: this.noteCategory,
          company: this.company,
        })
        if (data.affectedRows == 1) {
          this.clearNote()
          this.getNoteCategory()

          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Note Category added successfully."
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
}

input {
  line-height: 1.5;
  font-size: 1.1rem;
  padding: 6px 6px;
  width: 100%;
  grid-column: 5/-1;
  border-radius: 10px;
  outline: none;
  border: 1px solid black;
}

input:read-only {
  background-color: transparent !important;
  border: none;
}

button {
  width: 75px;
  margin-left: 0;
}

thead th {
  text-align: center !important;
}

.body-container {
  align-self: unset;
}

.new-note-categ-input {
  width: 420px;
}

.new-note-categ-form {
  display: flex;
  width: 50%;
  margin: 1rem 0;
  gap: 0 10px;
  align-items: center;
}

.note-category-table {
  width: 845px !important;
  table-layout: auto;
}

.update-note-categ-input {
  width: 420px;
}

.update-btn-td {
  display: flex;
  justify-content: space-evenly;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.575rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group {
  width: 420px;
}

.btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-size: 14px;
  padding: 0.575rem 0.75rem;
  border-radius: 20px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  /*  border-color: #6c757d;*/
  font-size: 14px;
  /*  border: 1px solid rgba(27, 31, 35, 0.15);*/
  padding: 0.575rem 0.75rem;
  border-radius: 20px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  pointer-events: none;
  opacity: 0.65;
}
</style>
