<template>
  <button class="btn-cancel">{{ label }}</button>
</template>

<script>
export default {
  name: 'ButtonCancel',
  props: {
    label: String,
  },
}
</script>

<style scoped>
button {
  border: 1px;
  box-shadow: 2px 4px #ccc;
  height: 35px;
  border-radius: 20px;
}
</style>
