<script setup>
// import Header from "./Header.vue"
// import MemberInformationBox from "./MemberInformationBox.vue"
// import axios from "axios"
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import BaseButton from "./UI/BaseButton.vue";
import BaseInput from "./UI/BaseInput.vue";
import ButtonSubmit from "./ButtonSubmit.vue";
import ButtonCancel from "./ButtonCancel.vue";
import BaseTooltip from "./UI/BaseTooltip.vue";
import api from "../api/api.js";
import { flashMessage } from "../functions.js";
import Swal from "sweetalert2"
</script>

<template>
  <div class="flex-child">
    <div class="body-container">
      <!-- Sidebar -->
      <div class="sidebar" :class="{ 'active': isSidebarOpen }">
        <button @click="toggleSidebar" class="open-btn">
          <div v-if="isSidebarOpen">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
          <div v-else>
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
        </button>
        <!-- Start of 2FA Feature Code -->
        <div class="flex-container">
          <div class="two-fa-container">
            <h4 class="two-fa-label">2FA</h4>
            <div class="flex">
              <div class="opt-in-group">

                <div class="opt-in-input-group">
                  <select class="select-categories form-control-select" name="selectedCountryCode"
                    v-model="selectedCountryCode" style="font-size: 16px; text-align: right">
                    <option v-for="code in countryCodes" :key="code" :value="code">{{ code }}</option>
                  </select>

                  <BaseInput type="text" v-model="manualInputOptInNumber" @input="handleNumericInput"
                    style="font-size: 16px;" />
                </div>

                <ButtonSubmit @click="optIn()" label="Opt In"
                  style="align-self: flex-start; margin-right: 10px; margin-bottom: 10px; margin-top: 12px;"
                  :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
              </div>
              <div style="width: 100%;">
                <hr>
              </div>
              <div class="send-message-group">
                <select class="select-categories form-control-select" name="phoneSendOTP" v-model="phoneSendOTP"
                  style="font-size: 16px;">
                  <option value="" disabled selected>
                    Select Phone Number
                  </option>
                  <option v-for="(phone, index) in Object.entries(optInCellPhones)" :key="index" :value="phone[1]"
                      :style="{ color: '#48b02c' }">
                      {{ formatPhoneNumber(phone[1]) }}
                  </option>
                </select>

                <!-- Button to Send SMS -->
                <ButtonSubmit @click="sendSMS" label="Send SMS with OTP"
                  style="align-self: flex-start; margin-bottom: 4px; margin-right: 10px;"
                  :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
                <ButtonSubmit @click="optOut" label="Opt Out"
                  style="align-self: flex-end; width:90px; margin-bottom: 4px;"
                  :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
              </div>
              <!-- OTP Validation Section -->
              <div class="otp-container">
                <BaseInput v-model="otpInput" placeholder="OTP" style="font-size: 16px; width: 140px" />
                <div v-if="verificationAttempted" class="verification-status">
                  <img v-if="isValid" :src="logocheck" alt="check" class="status-icon" />
                  <img v-else :src="logoclose" alt="invalid" class="status-icon" />
                </div>
              </div>
              <ButtonSubmit @click="verifyOTP" label="Validate"
                style="align-self: flex-start; margin-top: 12px; width:140px"
                :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
            </div>
          </div>
        </div>
        <!-- End of 2FA Feature Section -->
      </div>

      <!-- Sidebar end-->
      <header>
        <h2>Member Information</h2>
        <div>
          <div style="display: flex; justify-content: center;  gap: 10px; margin-top: -8px;" class="form">
            <a style="text-decoration: none;" id="screen-pop" class="btn-submit" @click="mnetapplicationpop" :disabled="false"
              v-if="authStatus_num === '1' || authStatus_num === '3'">
              <span class="screen-pop-lg">Go to Account </span> <font-awesome-icon class="screen-pop-sm" :icon="['fas', 'arrow-up-right-from-square']" />
            </a>
            <BaseButton v-else class="btn-submit" @click="mnetapplicationpop" :disabled="true">Go to Account
            </BaseButton>
            <h2 style="display: inline-block">M01</h2>
          </div>

        </div>
      </header>

      <div id="subheader" v-if="callerInfo && callerInfo.result" class="member-info">
        <span id="subheader1">
          <div>
            <span>Member Number: </span>
            <span id="label_member_number">{{
              callerInfo.result.MemberNumber || ""
              }} </span>
              
            <span id="logocontainer"
              v-if="callerInfo.result.MemberName && callerInfo.result.MemberNumber && callerInfo.result.MemberNumber.trim() !== ''">
              <img class="membernumber-icon" title="Valid Member Number" :src="logocheck" alt="check" />
              <button id='copymemberbtn' ref="copyMemberButton" style="cursor:pointer" @click="copyMemberToClipboard"> <div class="ttcopymembtn">Copy to Clipboard</div><img src="../assets/clipboard_icon.png"></button>
              <BaseTooltip :isVisible="showTooltipMemberNumber">
                Member Number Copied
              </BaseTooltip>
            </span>

            <img
              v-if="!callerInfo.result.MemberName && callerInfo.result.MemberNumber && callerInfo.result.MemberNumber.trim() !== ''"
              class="membernumber-icon" title="Invalid Member Number" :src="logoclose" alt="invalid" />

            <div style="display: inline-block; margin-right: 20px" class="form">
            </div>
          </div>
          <div>
            <span>Name: </span>
            <span id="label_member_name">{{
              callerInfo.result.MemberName || ""
              }}</span>
          </div>
        </span>
        <span id="subheader2" ref="second_subheaderHeight">
          <div>
            <span>Status: </span>
            <b><span id="authstatus_value" :style="{ color: authStatusColor }">{{
              authStatus
                }}</span></b>

            <span v-if="authStatus_num === '0'" title="Not Yet Authenticated">
              <img class="membernumber-icon" :src="logoclose" alt="invalid" />
            </span>

            <span v-if="authStatus_num === '1'" title="Authenticated Valid Credential">
              <img class="membernumber-icon" :src="logocheck" alt="check" />
            </span>

            <span v-if="authStatus_num === '2' && !callerInfo.result.FailureReason" title="Invalid Credential">
              <img class="membernumber-icon" :src="logominus" alt="invalid" />
            </span>

            <span v-show="authStatus_num === '2' && callerInfo.result.FailureReason"
              :title="callerInfo.result.FailureReason">
              <img class="membernumber-icon" :src="blackminus" alt="invalid" />
            </span>

            <span v-if="authStatus_num === '3'" title="Manually Authenticated">
              <img class="membernumber-icon" :src="logocheck" alt="check" />
            </span>

            <span v-if="authStatus_num === '4'" title="Manually Failed">
              <img class="membernumber-icon" :src="logoclose" alt="invalid" />
            </span>

            <span v-if="authStatus_num === '5'" title="Invalid Status">
              <img class="membernumber-icon" :src="logoclose" alt="invalid" />
            </span>
          </div>
          <div>
            <span>Reason: </span>
            <span id="label_attempttype">{{ attemptType }}</span>
          </div>
          <div style="display:flex;align-items: start;">
            <div style="padding: 6px 8px 0px" id="phonenum_list" v-html="phonenumList"></div>
            <button v-if="callerIdFlag" id='copyidbtn' ref="copyIdToOptInButton" style="cursor:pointer" @click="copyIdToOptIn">
              <div style="display:flex;align-items: center;">
              <svg style="display: block;" class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M13 7v-6l11 11-11 11v-6h-13v-10z" fill="grey"/>
              </svg>
              <button class="copyidbtn_class">
                Opt In</button>
                <div class="ttcopyidbtn">Copy To Opt In Field</div>
              </div>
            </button>
            <BaseTooltip :isVisible="showTooltipCallerID">
                Copied Caller ID and Pasted to Opt-In
            </BaseTooltip>
          </div>
          
          <!-- <div :style="{ padding: '10px 0', height: phonenumList ? 'auto' : '50px', width: '77%' } "> -->
        </span>
        <span id="subheader3">
          <!-- 2FA Section - MS05162024 -->
          <!-- Start of 2FA Feature Code -->
          
          <div class="flex-container">
            <div class="two-fa-container">
              <h4 class="two-fa-label">2FA</h4>
              <div class="flex">
                <div class="opt-in-group">

                  <div class="opt-in-input-group">
                    <select class="select-categories form-control-select" name="selectedCountryCode"
                      v-model="selectedCountryCode" style="font-size: 16px; text-align: right">
                      <option v-for="code in countryCodes" :key="code" :value="code">{{ code }}</option>
                    </select>

                    <BaseInput type="text" v-model="manualInputOptInNumber" @input="handleNumericInput"
                      style="font-size: 16px;" />
                  </div>

                  <ButtonSubmit @click="optIn()" label="Opt In"
                    style="align-self: flex-start; margin-right: 10px; margin-bottom: 10px; margin-top: 12px;"
                    :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
                </div>
                <div style="width: 100%;">
                  <hr>
                </div>
                <div class="send-message-group">
                  <select class="select-categories form-control-select" name="phoneSendOTP" v-model="phoneSendOTP"
                    style="font-size: 16px;">
                    <option value="" disabled selected>
                      Select Phone Number
                    </option>
                    <option v-for="(phone, index) in Object.entries(optInCellPhones)" :key="index" :value="phone[1]"
                      :style="{ color: '#48b02c' }">
                      {{ formatPhoneNumber(phone[1]) }}
                    </option>
                  </select>

                  <!-- Button to Send SMS -->
                  <ButtonSubmit @click="sendSMS" label="Send SMS with OTP"
                    style="align-self: flex-start; margin-bottom: 4px; margin-right: 10px;"
                    :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
                  <ButtonSubmit @click="optOut" label="Opt Out"
                    style="align-self: flex-end; width:90px; margin-bottom: 4px;"
                    :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
                </div>
                <!-- OTP Validation Section -->
                <div class="otp-container">
                  <BaseInput v-model="otpInput" placeholder="OTP" style="font-size: 16px; width: 140px" />
                  <div v-if="verificationAttempted" class="verification-status">
                    <img v-if="isValid" :src="logocheck" alt="check" class="status-icon" />
                    <img v-else :src="logoclose" alt="invalid" class="status-icon" />
                  </div>
                </div>
                <ButtonSubmit @click="verifyOTP" label="Validate"
                  style="align-self: flex-start; margin-top: 12px; width:140px"
                  :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''" />
              </div>
            </div>
          </div>
          <!-- End of 2FA Feature Section -->




        </span>
      </div>

      <div id="centerdiv">

        <div class="containerdiv" id="containerdiv1">
          <div>
            <form @submit.prevent="handleSendRadio()" method="post">
              <div class="radiolist">
                <div style="display: flex;  margin-top: 13px;">
                  <input type="radio" id="account_number" name="member_type" value="account_number"
                    v-model="selectedMemberType">
                  <label for="account_number">Account Number</label>
                </div>
                <div style="display: flex;  margin-top: 13px;">
                  <input type="radio" id="ssn" name="member_type" value="ssn" v-model="selectedMemberType">
                  <label for="ssn">SSN</label>
                </div>
              </div>
              <div>
                <BaseInput type="text" id="member_number_or_credit_card" name="member_number_or_credit_card"
                  v-model="inputNo" class="form-control"
                  style="padding-left: 10px; font-size: 16px; margin-top: 3px; max-width: 280px"
                  :disabled="!selectedMemberType" />
              </div>
              <ButtonSubmit style="margin-top: 13.5px" label="Send" :disabled="isSendButtonDisabled" />
              <ButtonCancel style="margin-top: 10px" type="button" label="Cancel" @click="handleCancel('membernumber')"
                :disabled="!inputNo" />
            </form>
            <!-- End of Radio Button -->

            <div>
              <div>
                <table id="table" class="member-info-table">
                  <caption>
                    Member Information
                  </caption>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Relationship</th>
                    </tr>
                  </thead>
                  <tbody style="display: block; max-height: calc(5 * 2.5em); overflow-y: auto;">
                    <!-- <tr>
                  <td
                    v-if="
                      callerInfo &&
                      callerInfo.result &&
                      callerInfo.result.MemberName && callerInfo.result.MemberName.trim() !== ''
                    "
                  >
                    {{ callerInfo.result.MemberName }}
                  </td>
                  <td
                    v-if="
                      callerInfo &&
                      callerInfo.result &&
                      callerInfo.result.MemberName && callerInfo.result.MemberName.trim() !== ''
                    "
                  >
                    Primary
                  </td>
                </tr> -->
                    <template v-if="callerInfo && callerInfo.result && callerInfo.result.Accounts">
                      <tr v-for="(account, index) in callerInfo.result.Accounts.sort((a, b) => {
                        if (a.type < b.type) return 1;
                        if (a.type > b.type) return -1;
                        return 0;
                      })" :key="index">

                        <td v-if="account.Name && account.Name.trim() !== ''">
                          {{ account.Name.toUpperCase() }}
                        </td>
                        <td v-if="account.Name && account.Name.trim() !== ''">
                          {{ account.type }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <!-- <div style="margin-top: 30px">
            <table id="table" class="member-info-table">
              <caption>
                Security Notes
              <router-link to="/notes" style="text-decoration: none; color: #0e5071;">
                <font-awesome-icon :icon="['fas', 'circle-info']" />
              </router-link>
              </caption>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(fraudNote, index) in fraudNotes">
                  <td>{{fraudNote.NoteCategory}}</td>
                  <td>{{fraudNote.NoteText}} </td>
                </tr>
              </tbody>
            </table>
          </div> -->

            </div>
            <!-- <div v-if="isDemo" style="margin-top: 20px;"> -->
            <!-- <div style="margin-top: 20px">
            <h4>Fraud Warnings</h4>

            <textarea
              v-if="isGetThreshold"
              disabled
              class="form-control-textarea"
              style="font-size: 16px; width: 380px; color: red"
              v-model="threshold"
              cols="15"
              rows="5"
            ></textarea>

            <textarea
              v-else
              disabled
              class="form-control-textarea"
              style="font-size: 16px; width: 380px; color: red"
              cols="15"
              rows="5"
            ></textarea>
          </div> -->
          </div>
        </div>

        <div class="containerdiv member-info" id="containerdiv2" v-if="callerInfo && callerInfo.result">
          <div class="member-info-content">
            <form method="post">
              <div>
                <h4>Authentication Status</h4>
                <div class="btn-auth-group" style="margin-top: 10px">
                  <button class="btn-manually-auth" :class="{ 'btn-clicked-green': AuthclickedButton === 3 }"
                    @click.prevent="passValueAuth(3, $event)" :disabled="!callerInfo.result.MemberNumber ||
                      Object.keys(callerInfo.result).length === 0
                      ">
                    <span class="btn-text-large">Manually Authenticate</span>
                  </button>
                  <button class="btn-manually-failed" :class="{ 'btn-clicked-red': AuthclickedButton === 4 }"
                    @click.prevent="passValueAuth(4, $event)" :disabled="!callerInfo.result.MemberNumber ||
                      Object.keys(callerInfo.result).length === 0">
                    <span class="btn-text-large">Manually Fail</span>
                  </button>
                </div>
              </div>

              <div v-if="isLoadingAuth">
                <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
              </div>
              <ButtonSubmit style="margin-top: 10px" v-if="!isLoadingAuth" class="btn-submit" type="submit" label="Save"
                @click.prevent="handleAuth()" :disabled="!isAuthVerifed" />
              <ButtonCancel style="margin-top: 10px" v-if="!isLoadingAuth" label="Cancel" type="button"
                @click="handleCancel('authstatus')" :disabled="!isAuthVerifed" />
            </form>
          </div>
          <div>

            <div>
              <form @submit.prevent="handleNote()" style="margin-top: 24px">
                <h4 style="display:flex; align-items: center">Security Notes 
                  <router-link  to="/notes" style="text-decoration:none">
                    <span class="numberbadge"
                    v-if="fraudNotes.length">{{ fraudNotes.length }}</span>
                  </router-link>
                </h4>
                <div v-if="isLoadingNotes">
                  <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
                </div>
                <div style="margin: 10px 0 15px" v-if="!isLoadingNotes">
                  <select class="select-categories form-control-select" name="category" v-model="category">
                    <option value="" disabled selected hidden>Note Category</option>
                    <option v-for="category in noteCategories" :key="category" :value="category">
                      {{ category }}
                    </option>
                  </select>
                </div>
                <div>
                  <label for="">Notes</label>
                  <textarea class="form-control-textarea" style="font-size: 16px; min-width: 97%; max-width: 97%"
                    v-model="notes" cols="15" rows="5"></textarea>
                </div>
                <!-- <BaseButton style="margin-top: 5px" class="btn-submit" :isDisabled="isSaveEnabled">Save</BaseButton> -->
                <ButtonSubmit style="margin-top: 10px" label="Save" :disabled="isSaveEnabled" />
                <ButtonCancel type="button" style="margin-top: 10px" label="Cancel"
                  @click="handleCancel('notecategory')" :disabled="isSaveEnabled" />
              </form>
            </div>
          </div>

        </div>


        <div class="containerdiv member-info" id="containerdiv3"
          :style="{ marginTop: secondSubheaderheight }">


          <div id="warning-code-container">
            <div class="warning-code-col">
              <table id="table" class="warning-code-table">
                <caption>
                  Warning Code
                </caption>
                <thead>
                  <tr>
                    <th style="min-width: 110px">Warning Code</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <div v-if="isLoadingWarningCode">
                  <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
                </div>
                <tbody v-if="
                  symitarWarningCodes &&
                  symitarWarningCodes.length &&
                  !isLoadingWarningCode
                ">
                  <tr v-for="(item, index) in symitarWarningCodes" :key="index">
                    <td style="text-align: right; width: 40px" v-if="symitarWarningCodes && symitarWarningCodes.length">
                      {{ item.WarningCodes }}
                    </td>
                    <td v-if="symitarWarningCodes && symitarWarningCodes.length">
                      {{ item.Description }}
                    </td>
                  </tr>
                </tbody>
                <!-- <tbody>
                <tr>
                  <td style="text-align: right; width: 40px">
                    001
                  </td>
                  <td>
                    Hello world
                  </td>
                </tr>
              </tbody> -->
              </table>
            </div>
          </div>

        </div>
      </div>


      <div v-if="callerInfo && callerInfo.result" class="member-info">

      </div>

      <div class="member-info-content">
        <!-- Changed the label to Radio Button - MS05212024 -->
      </div>

      <!-- modal mnet core client  -->
      <div class="modal" v-show="isOpenMnet" @click="closeModal">
        <div class="modal-content" @click.stop>
          <img class="modal-image" :src="logoPath" alt="MNET Core Access" />
        </div>
      </div>

      <dialog :open="modalOpen">
        <div class="dialog-backdrop"></div>
        <div class="dialog-body">
          <form method="dialog">
            <h4>Select an account number below then click Send</h4>
            <form @submit.prevent="handleSendRadio()">
              <select :disabled="!accountNumbers.length" class="select-categories form-control-select"
                name="accountNumbers" v-model="accountNumberSelected">
                <option value="" disabled selected hidden></option>
                <option v-for="accountNumber in accountNumbers" :key="accountNumber" :value="accountNumber">
                  {{ accountNumber }}
                </option>
              </select>
              <!-- <BaseButton style="margin-top: 5px" class="btn-submit" :isDisabled="isSaveEnabled">Save</BaseButton> -->
              <ButtonSubmit style="margin-top: 10px" label="Send" :disabled="isAccountNumberSendButtonDisabled" />
              <ButtonCancel type="button" style="margin-top: 10px" label="Cancel" @click="handleCancel('accountnumber')"
                :disabled="isAccountNumberSendButtonDisabled" />
            </form>
            <div class="button-group" style="margin-top: 20px">
              <ButtonSubmit label="Close" @click="modalOpen = false" />
            </div>
          </form>
        </div>
      </dialog>

    </div>
  </div>

</template>

<script>
export default {
  name: "MemberInformation",

  data: () => ({
    company: localStorage.getItem("companyID"),
    setColor: "#007bff",
    setSize: "50px",
    isLoading: true,
    isLoadingWarningCode: true,
    isLoadingNotes: false,
    isLoadingAuth: false,
    isSidebarOpen: false,
    phonenumList: "",
    category: "",
    notes: "",
    inputNo: "",
    authToken: localStorage.getItem("authToken"),
    companyID: "",
    authResult: "",
    AuthclickedButton: null,
    userWarningCodes: [],
    symitarWarningCodes: [],
    noteCategories: [], // Store the note categories
    authStatus: "",
    authStatusColor: "",
    attemptType: "",
    authStatus_num: "",
    callerInfo: {
      status: "",
      message: "",
      result: {},
    },
    authCall: {
      status: "",
      message: "",
      result: {},
    },
    updateCall: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    updateCallerInfo: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    warningCodes: {
      status: "",
      num_rows: "",
      result: [],
    },
    accountNumbers: [],
    accountNumberSelected: '',
    isOpenMnet: false,
    logoPath: require("@/assets/mnetCoreClient.jpg"),
    logocheck: require("@/assets/check.png"),
    logoclose: require("@/assets/close.png"),
    logominus: require("@/assets/minus.png"),
    blackminus: require("@/assets/blackminus.png"),
    modalOpen: false,
    MaxNumCallsCaller: null,
    NumHoursCaller: null,
    isGetThreshold: false,
    isDemo: false,
    callerID: null,
    threshold: "",
    // Adding the data for sending SMS with OTP - MS05142024
    phoneSendOTP: "",
    otpInput: "",
    verificationAttempted: false,
    isValid: false,
    enrolledNumbers: [],
    formatNumber: "",
    // Adding the Radio Function
    selectedMemberType: null,
    // Add Country Codes
    countryCodes: [],
    selectedCountryCode: '+1',
    manualInputOptInNumber: '',
    cellPhones: [],
    optInCellPhones: [],
    optInPhoneNumberSelected: '',
    fraudNotes: [],
    callerIdFlag: false,
    showTooltipCallerID: false,
    showTooltipMemberNumber: false,
    second_subheaderHeight:''

  }),
  props: {
    userData: Object,
    brandData: Object,
  },
  async mounted() {
    /* console.log("Callerinfo", this.callerInfo.result.data); */
    // console.log("joint", this.JointOwnersData)

    this.setColor = this.brandData.accent_color1;
    // /* console.log("brandData", this.brandData.main_color1); */

    /* console.log(this.company); */
    if (this.$route.query.AuthToken) {
      this.authToken = this.$route.query.AuthToken;
    } else {
      this.authToken = localStorage.getItem("authToken");
    }
    this.getCountryCode()
    //await this.isDemoURL()
    await this.GetCallerInfoUsingAuthToken();
    await this.getThreshold();
    await this.getThresholdCounter();
    await this.fetchNotesCategory();
    
  },
  computed: {
    /* filteredWarningCodes() {
      return this.warningCodes.result.filter((code) => this.userWarningCodes.includes(code.WarningCode))
    },*/
    // getJointOwners() {
    //   return this.callerInfo.result.Accounts.JointOwnership.map(owner => ({
    //     Name: owner.Name
    //   }))
    // },

    // Adding the Radio Function Mark May 21, 2024
    isSendButtonDisabled() {
      return !this.inputNo || !this.selectedMemberType;
    },
    isAccountNumberSendButtonDisabled() {
      return !this.accountNumbers || !this.accountNumberSelected;
    },

    //get second subheader height
    secondSubheaderheight() {
      if(this.second_subheaderHeight){
        return `${380 - this.second_subheaderHeight}px`
      }

      return '300px';
    },

    // Until Here Mark May 21, 2024

    isSendVerified() {
      return (
        !this.inputNo.trim() || Object.keys(this.callerInfo.result).length === 0
      );
    },
    isAuthVerifed() {
      return this.AuthclickedButton === 3 || this.AuthclickedButton === 4;
    },
    isSaveEnabled() {
      if (Object.keys(this.callerInfo.result).length <= 0) {
        return true;
      } else {
        if (this.category || this.notes) {
          return false;
        }
      }
      return true;
    },

  },
  methods: {

    showTooltipAboveButton(btnname) {
      this.showTooltipMemberNumber = false;
      this.showTooltipCallerID = false;
      const button = btnname == "member"?this.$refs.copyMemberButton:this.$refs.copyIdToOptInButton;
      if(btnname == "member")
      this.showTooltipMemberNumber = true;
      else if (btnname == "id")
      this.showTooltipCallerID = true;

      this.$nextTick(() => {
        const tooltip = this.$el.querySelector('.tooltip');
        const buttonRect = button.getBoundingClientRect();

        if(btnname == "member"){
          tooltip.style.top = `${buttonRect.top - tooltip.offsetHeight + 30}px`;
          tooltip.style.left = `${buttonRect.left + buttonRect.width / 2}px`;}
        
        else if (btnname == "id"){
          tooltip.style.top = `${buttonRect.top - tooltip.offsetHeight + 30}px`;
          tooltip.style.left = `${buttonRect.left + buttonRect.width / 2}px`;
        }
        
        tooltip.style.opacity = 1;
        // Hide the tooltip after a short delay
        
        setTimeout(() => {
          tooltip.style.opacity = 0;
        }, 1700);
        setTimeout(() => {
          if(btnname == "member")
          this.showTooltipMemberNumber = false;
          else if (btnname == "id")
          this.showTooltipCallerID = false;
        }, 2000);
      });
    },

    copyMemberToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.callerInfo.result.MemberNumber).then(
          () => {
            this.showTooltipAboveButton("member");
          },
          (err) => {
            console.log(err)
          }
        );
      }
    },

    copyIdToOptIn() {
      const CallerID = this.callerInfo.result.CallerID.replace('+1','');
      navigator.clipboard.writeText(CallerID)
      this.manualInputOptInNumber = CallerID;
      this.showTooltipAboveButton("id")
    },

    isMemberNameExist(memberName) {
      return (
        this.callerInfo.result &&
        this.callerInfo.result.MemberName &&
        this.callerInfo.result.MemberName.toUpperCase() === memberName
      );
    },

    async isDemoURL() {
      const currentURL = window.location.href;
      const baseURL = currentURL.split("#")[0].replace(/\/$/, ""); // Remove trailing slash

      if (this.authToken) {
        // if (baseURL == "http://localhost:8080") {
        if (
          baseURL == "https://demo38web.core-access.com" ||
          baseURL == "https://sikorskyweb.core-access.com"
        ) {
          this.isDemo = true;
        } else {
          this.isDemo = false;
        }
      }
    },
    async getThreshold() {
      try {
        const result = await api.get(`update_threshold/${this.company}`);

        if (result.data.length > 0) {
          this.MaxNumCallsCaller = result.data[0].MaxNumCallsCaller;
          this.NumHoursCaller = result.data[0].NumHoursCaller;
        }
      } catch (error) {
        /* console.log(error); */
      }
    },

    async getThresholdCounter() {
      try {
        const computedNumHoursCaller = this.NumHoursCaller;
        const phoneNumberWithoutTel = this.callerID.replace(/^tel:/, "");

        const data = {
          Company: this.company,
          CallerID: phoneNumberWithoutTel,
          NumHoursCaller: computedNumHoursCaller,
        };
        // const result = await api.post(`http://localhost:443/get-threshold-counter`, data)
        const result = await api.post(`get-threshold-counter`, data);
        /* console.log("result"); */
        /* console.log(result.data); */

        if (result.data.length > 0) {
          // if (result.data[0].countIndex == 0) {
          if (result.data[0].countIndex >= this.MaxNumCallsCaller) {
            if (this.authToken) {
              this.threshold = `Global Warning: This caller ID has called ${this.MaxNumCallsCaller} or more times in the last ${this.NumHoursCaller} hours.`;
              this.isGetThreshold = true;
            } else {
              this.isGetThreshold = false;
            }
          }
        }
      } catch (error) {
        /* console.log(error); */
      }
    },

    closeModal() {
      this.isOpenMnet = false;
    },
    async mnetapplicationpop() {
      const memberNumber = this.callerInfo.result.MemberNumber;
      document.getElementById("screen-pop").href = "mnetpop:" + memberNumber;
    },
    episyspop() {
      // const memberNumber = localStorage.getItem("MemberNumber") || "";
      const memberNumber = this.callerInfo.result.MemberNumber;
      window.location.href = `EpisysPop:${memberNumber}`;
    },
    //sidebar method
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    

    //  Copy the function for Radio Button from Sikorsky Project
    async handleSendRadio() {
      this.phoneSendOTP = '';
      try {
        if (this.selectedMemberType === 'ssn') {
          // const res = await api.get(`/get-account-ssn?company=${this.company}&authToken=${this.authToken}&taxId=${this.inputNo}`);
          /* console.log(`resp from get-account-ssn: ${JSON.stringify(res)}`); */
          // await this.GetCallerInfoUsingAuthToken();
          //       if (res.status === 200 && res.data.Response == true) {
          //         /* console.log("API Response:", res.data); */

          //           this.callerInfo.result.Accounts = res.data.Accounts;
          //           this.callerInfo.result.MemberNumber = res.data.MemberNumber;
          //           this.callerInfo.result.MemberName = res.data.MemberName;
          //           this.callerInfo.result.PhoneNumbers = res.data.PhoneNumbers;
          //           this.callerInfo.result.AttemptType = res.data.AttemptType;
          //           this.readCallerAuthData(
          //           this.authStatus_num,
          //           this.callerInfo.result.AttemptType,
          //           this.callerInfo.result.PhoneNumbers,
          //           this.callerInfo.result.CallerID
          //           );
          //           /* console.log('attemptNum after'); */
          //           flashMessage(
          //           this.$flashMessage,
          //           this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          //           this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          //           "Updating caller info based on Tax ID."
          //         )
          //         console.log("TaxId", this.callerInfo.result)
          //       }
          //       else{

          // 	this.callerInfo.result.Accounts = [];
          //           this.callerInfo.result.MemberNumber = '';
          //           this.callerInfo.result.MemberName = '';
          //           this.callerInfo.result.PhoneNumbers = { cellPhones: [], other: [] };
          //           this.readCallerAuthData(
          //             "0",
          //             "2",
          //           this.callerInfo.result.PhoneNumbers,
          //           this.callerInfo.result.CallerID
          //           );

          //         /* console.log(`resp from get-account-ssn: ${JSON.stringify(res)}`); */
          //           flashMessage(
          //           this.$flashMessage,
          //           this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          //           this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          //           "Member Not found"
          //           )
          //       }

          let res = await api.get(`/v3/accounts/ssn?ssn=${this.inputNo}&company=${this.company}`);
          // const res = {
          //   "status": 200,
          //   "data": {
          //     "response": {
          //       "AccountNumber": [
          //         "0005117719",
          //         "0999333333",
          //         "0005132396"
          //       ]
          //     }
          //   }
          // };

          // Check if AccountNumber is a string or convert to array if necessary
          if (typeof res.data.response.AccountNumber === 'string') {
            res.data.response.AccountNumber = [res.data.response.AccountNumber];
          } else if (!Array.isArray(res.data.response.AccountNumber)) {
            res.data.response.AccountNumber = [];
          }

          if (res.status === 200 && res.data.response.AccountNumber.length !== 0) {
            /* console.log("API Response:", res.data); */
            this.accountNumbers = res.data.response.AccountNumber;
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Account Numbers Found Select an account number below"
            )
            this.modalOpen = true
          }
          else {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "No Account Numbers Found"
            )
          }
        }
        if (this.selectedMemberType === 'account_number') {
          const res = await api.get(`/get-account-number?company=${this.company}&authToken=${this.authToken}&accountNumber=${this.inputNo}`);
          await this.GetCallerInfoUsingAuthToken();
          if (res.status === 200 && res.data.Response == true) {

            this.callerInfo.result.Accounts = res.data.Accounts;
            this.callerInfo.result.MemberNumber = res.data.MemberNumber;
            this.callerInfo.result.MemberName = res.data.MemberName;
            this.callerInfo.result.PhoneNumbers = res.data.PhoneNumbers;
            //this.callerInfo.result.AttemptType = res.data.AttemptType;
            this.readCallerAuthData(
              this.authStatus_num,
              this.callerInfo.result.AttemptType,
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID
            );
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Updating caller info based on Account Number."
            );

            // console.log("accountNumber", this.callerInfo.result)
          }
          else {

            this.callerInfo.result.Accounts = [];
            this.callerInfo.result.MemberNumber = '';
            this.callerInfo.result.MemberName = '';
            this.callerInfo.result.PhoneNumbers = { cellPhones: [], other: [] };
            this.readCallerAuthData(
              "0",
              "2",
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID
            );

            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Member Not found"
            )
          }
        }

        if (!this.selectedMemberType && this.accountNumberSelected) {
          const res = await api.get(`/get-account-number?company=${this.company}&authToken=${this.authToken}&accountNumber=${this.accountNumberSelected}`);
          await this.GetCallerInfoUsingAuthToken();
          if (res.status === 200 && res.data.Response == true) {
            this.callerInfo.result.Accounts = res.data.Accounts;
            this.callerInfo.result.MemberNumber = res.data.MemberNumber;
            this.callerInfo.result.MemberName = res.data.MemberName;
            this.callerInfo.result.PhoneNumbers = res.data.PhoneNumbers;
            //this.callerInfo.result.AttemptType = res.data.AttemptType;
            this.readCallerAuthData(
              this.authStatus_num,
              this.callerInfo.result.AttemptType,
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID
            );
            
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Updating caller info based on Account Number."
            )
            // console.log("accountNumber", this.callerInfo.result)
          }
          else {
            this.callerInfo.result.Accounts = [];
            this.callerInfo.result.MemberNumber = '';
            this.callerInfo.result.MemberName = '';
            this.callerInfo.result.PhoneNumbers = { cellPhones: [], other: [] };
            this.readCallerAuthData(
              "0",
              "2",
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID
            );

            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Member Not found"
            )
          }
        }

      } catch (error) {
        /* console.log(error); */
      }
      this.inputNo = '';
      this.selectedMemberType = null;
      this.accountNumberSelected = "";

    },
    // End Copy the function for Radio Button from Sikorsky Project

    async handleSend() {
      this.isLoading = true;
      // this.isLoadingWarningCode = true;
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        // PHP START
        // const resCallerInfo = await axios.get(
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=get_caller_info_by_id&IndexNum=${this.authToken}&company=1285`,
        //   { headers }
        // )
        // if (resCallerInfo.status === 200) {
        //   this.isLoading = false
        //   this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length)
        //   console.log("callerInfo by ID API result = ", resCallerInfo.data)
        //   this.callerInfo.status = resCallerInfo.data.status
        //   this.callerInfo.message = resCallerInfo.data.message
        //   this.authResult = await this.authenticateMethod(
        //     this.inputNo.length,
        //     this.inputNo,
        //     resCallerInfo.data.result.CallerID,
        //     this.authToken,
        //     this.companyID
        //   )
        //   console.log("this.authResult.Response", this.authResult.Response)
        //   if (this.authResult.Response == "YES") {
        //     const resCallerInfoUpdated = await axios.get(
        //       `https://genesys-api.gcm3.com/apiNODE/api.php?action=get_caller_info_by_id&IndexNum=${this.authToken}&company=1285`,
        //       { headers }
        //     )
        //     this.callerInfo.result = resCallerInfoUpdated.data.result
        //     this.readCallerAuthData(
        //       this.callerInfo.result.AuthStatus,
        //       this.callerInfo.result.AttemptType,
        //       this.callerInfo.result.PhoneNumbers,
        //       this.callerInfo.result.CallerID
        //     )
        //     console.log("User Warning Codes : ", resCallerInfoUpdated.data.result.WarningCodes)
        //     console.log("User this.authToken : ", this.authToken)
        //     localStorage.setItem("MemberNumber", this.callerInfo.result.MemberNumber)
        //     console.log("MemberNumber MI HANDLE SEND", localStorage.getItem("MemberNumber"))
        //     this.userWarningCodes = resCallerInfo.data.result.WarningCodes
        //     this.inputNo = ""
        //     if (this.inputNo.length === 16) {
        //       flashMessage(
        //         this.$flashMessage,
        //         this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //         this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //         "Updating caller info based on debit card number."
        //       )
        //     } else if (this.inputNo.length !== 9) {
        //       flashMessage(
        //         this.$flashMessage,
        //         this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //         this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //         "Updating caller info based on member number."
        //       )
        //     }

        //     if (this.authResult.WarningCodes) {
        //       // Populate warning codes
        //       // Loop through WarningCodes and populate each one
        //       const warningCodesArray = this.callerInfo.result.WarningCodes.split(",")
        //       console.log("warningCodesArray : ", warningCodesArray)
        //       // Inside the loop, update the code to assign the result directly to this.symitarWarningCodes
        //       for (const code of warningCodesArray) {
        //         await this.populateWarningCodes(code)
        //         console.log("symitarWarningCodes : ", code)
        //       }
        //       this.isLoadingWarningCode = false
        //     }
        //   } else if (this.authResult.Response == "NO") {
        //     flashMessage(
        //       this.$flashMessage,
        //       this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //       this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //       "Member number not found."
        //     )

        //     this.isLoadingWarningCode = false
        //   } else {
        //     flashMessage(
        //       this.$flashMessage,
        //       this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //       this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //       "Member number not found."
        //     )
        //     this.isLoadingWarningCode = false
        //   }
        // } else {
        //   flashMessage(
        //     this.$flashMessage,
        //     this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //     this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //     "No response from Authenticate.php endpoint."
        //   )
        // }

        // NODE START
        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        /* console.log(resCallerInfo); */

        if (resCallerInfo.status === 200) {
          this.callerID = resCallerInfo.data.CallerID;
          this.isLoading = false;
          this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length);
          this.callerInfo.status = resCallerInfo.status;
          // this.callerInfo.message = resCallerInfo.data.message

          // NEED VPN to open
          this.authResult = await this.authenticateMethod(
            this.inputNo.length,
            this.inputNo,
            resCallerInfo.data.CallerID,
            this.authToken,
            this.companyID
          );
          /* console.log("this.  ", this.authResult.Response); */
          if (this.authResult.Response == "YES") {
            const resCallerInfoUpdated = await api.get(
              `GetCallerInfo/${this.authToken}/${this.company}`
            );

            /* console.log(resCallerInfoUpdated); */

            this.callerInfo.result = resCallerInfoUpdated.data;
            //CHECKPOINT

            this.readCallerAuthData(
              this.callerInfo.result.AuthStatus,
              this.callerInfo.result.AttemptType,
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID,

            );
            /* console.log(resCallerInfo.data.WarningCodes); */
            // console.log(
            //   "User Warning Codes : ",
            //   resCallerInfoUpdated.data.WarningCodes
            // );
            /* console.log("User this.authToken : ", this.authToken); */
            localStorage.setItem(
              "MemberNumber",
              this.callerInfo.result.MemberNumber
            );
            //  console.log(
            //   "MemberNumber MI HANDLE SEND",
            //   localStorage.getItem("MemberNumber")
            // );
            this.userWarningCodes = resCallerInfo.data.WarningCodes;
            this.inputNo = "";
            if (this.inputNo.length === 16) {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#FF7F50",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                "Updating caller info based on debit card number."
              );
            } else if (this.inputNo.length !== 9) {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#FF7F50",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                "Updating caller info based on member number."
              );
            }

            if (this.authResult.WarningCodes) {
              // Populate warning codes
              // Loop through WarningCodes and populate each one
              const warningCodesArray =
                this.callerInfo.result.WarningCodes.split(",");
              /* console.log("warningCodesArray : ", warningCodesArray); */
              // Inside the loop, update the code to assign the result directly to this.symitarWarningCodes
              for (const code of warningCodesArray) {
                await this.populateWarningCodes(code);
                /* console.log("symitarWarningCodes : ", code); */
              }
              this.isLoadingWarningCode = false;
            }
          } else if (this.authResult == "NO") {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#FF7F50",
              this.brandData.flash_text_color
                ? this.brandData.flash_text_color
                : "#ffffff",
              "Member number not found."
            );

            this.isLoadingWarningCode = false;
          } else {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#FF7F50",
              this.brandData.flash_text_color
                ? this.brandData.flash_text_color
                : "#ffffff",
              "Member number not found."
            );
            this.isLoadingWarningCode = false;
          }
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "No response from Authenticate.php endpoint."
          );
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    // NEXT
    async handleAuth() {
      this.isLoadingAuth = true;
      this.isLoading = true;
      try {

        let FailureReason = "";
        let SuccessOrFail = "";

        if (this.AuthclickedButton === 3) {
          // Handle Manually Authenticated logic
          FailureReason = null;
          SuccessOrFail = this.AuthclickedButton.toString();
        } else if (this.AuthclickedButton === 4) {
          // Handle Manually Failed logic
          FailureReason = "Manually Failed by Agent";
          SuccessOrFail = this.AuthclickedButton.toString();
        }

        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        if (resCallerInfo.status === 200) {
          this.callerID = resCallerInfo.data.CallerID;
          this.callerInfo.result = resCallerInfo.data;
          this.authCall = await this.AddAuthCall(
            FailureReason,
            SuccessOrFail,
            this.callerInfo.result
          );
          /* console.log("authcall", this.authCall); */

          if (this.authCall.affectedRows === 1) {

            this.updateCall = await this.UpdateCallsByAuthToken(
              FailureReason,
              SuccessOrFail,
              this.callerInfo.result,
              this.authToken
            );
            /* console.log(SuccessOrFail); */
            if (this.updateCall.affectedRows === 1) {
              this.updateCallerInfo = await this.UpdateCallerInfo(
                SuccessOrFail,
                this.authToken
              );

              if (this.updateCallerInfo.affectedRows === 1) {
                const resCallerInfoUpdated = await api.get(
                  `GetCallerInfo/${this.authToken}/${this.company}`
                );

                this.callerInfo.result = resCallerInfoUpdated.data;
                this.callerInfo.result.Accounts = JSON.parse(resCallerInfoUpdated.data.Accounts)
                this.readCallerAuthData(
                  this.callerInfo.result.AuthStatus,
                  this.callerInfo.result.AttemptType,
                  this.callerInfo.result.PhoneNumbers,
                  this.callerInfo.result.CallerID
                );
                this.isLoadingAuth = false;
                this.isLoading = false;
                this.AuthclickedButton = 0;

                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#FF7F50",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  "Authentication status successfully updated."
                );
              }
            }
          }
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    async handleNote() {
      this.isLoading = true;
      try {
        // NODE START
        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        this.callerInfo.result = resCallerInfo.data;

        const body = {
          Company: this.company,
          Name: this.callerInfo.result.MemberName,
          MemberNumber: this.callerInfo.result.MemberNumber,
          PhoneNumber: this.callerInfo.result.CallerID,
          InteractionId: this.callerInfo.result.InteractionId,
          NoteCategory: this.category,
          NoteText: this.notes,
          CreatedByAgent: this.callerInfo.result.AgentName,
          AgentID: this.callerInfo.result.AgentId,
          AgentName: this.callerInfo.result.AgentName,
        };
        const resAddFraudnotes = await api.post("addFraudNotes", body);

        if (resAddFraudnotes.status === 200) {
          this.isLoading = false;
          this.category = "";
          this.notes = "";

          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Security note successfully added."
          );
          await this.GetCallerInfoUsingAuthToken();
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Security note not added."
          );
        }

      } catch (error) {
        /* console.log(error); */
      }
    },
    async GetCallerInfoUsingAuthToken() {
      this.isLoading = true;
      //  this.isLoadingWarningCode = true;
      try {
        const resCallerInfo = await api.get(`GetCallerInfo/${this.authToken}/${this.company}`);

        if (resCallerInfo.status === 200) {
          /* console.log("ResData", resCallerInfo.data, resCallerInfo); */

          if (resCallerInfo.data.Accounts !== null) {
            let originalAccounts = [];
            let accountObject = {};
            if (typeof resCallerInfo.data.Accounts === 'undefined') {
              const accountsColumn = resCallerInfo.data.Accounts;
              accountObject = JSON.parse(accountsColumn);
              const accountValue = accountObject.Accounts;
              originalAccounts = accountValue;
            } else {
              originalAccounts = JSON.parse(resCallerInfo.data.Accounts);
            }
            const uniqueNamesSet = new Set();
            let uniqueAccounts = originalAccounts.filter((account) => {
              const upperCaseOwner = account.Name.toUpperCase();
              // Check if the account name is empty, contains only whitespace, or is not a valid name
              const isRealName = upperCaseOwner.trim().length >= 0;
              if (!isRealName) {
                return false;
              }

              if (!uniqueNamesSet.has(upperCaseOwner)) {
                uniqueNamesSet.add(upperCaseOwner + account.Suffix);
                return true;
              }
              return false;
            });
            uniqueAccounts = uniqueAccounts.sort((a, b) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            });
            if (typeof resCallerInfo.data.Accounts === 'undefined') {
              this.uniqueAccounts = uniqueAccounts;
            } else {
              resCallerInfo.data.Accounts = uniqueAccounts;
              this.uniqueAccounts = resCallerInfo.data.Accounts;
            }
          } else {
            /* console.log("Accounts data is null."); */
          }

          this.callerID = resCallerInfo.data.CallerID;
          this.isLoading = false;
          if (resCallerInfo.status === 200) {
            if (resCallerInfo.data) {
              this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length);
              this.callerInfo.status = resCallerInfo.status;
              this.callerInfo.result = resCallerInfo.data;

              /* console.log(`passing this.callerInfo.result.PhoneNumbers`, this.callerInfo.result.PhoneNumbers); */

              this.readCallerAuthData(
                this.callerInfo.result.AuthStatus,
                this.callerInfo.result.AttemptType,
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
              );

              // this.userWarningCodes = resCallerInfo.data.WarningCodes;
              localStorage.setItem("authToken", this.authToken);
              localStorage.setItem("MemberNumber", this.callerInfo.result.MemberNumber);
              /* console.log('this.callerInfo.result', this.callerInfo.result); */
              // const warningCodesArray = this.callerInfo.result.WarningCodes ? this.callerInfo.result.WarningCodes.split(',') : [];

              // for (const code of warningCodesArray) {
              //   await this.populateWarningCodes(code);
              // }
            }
          }
          //  this.isLoadingWarningCode = false;
        }
        this.modalOpen = false;
        this.manualInputOptInNumber = '';
        this.selectedCountryCode = '+1';
        this.phoneSendOTP = '';
        this.otpInput = '';
        await this.getOptInPhoneNumberByMemberNumber()
        await this.fetchWarningCodes();
        await this.getFraudNotes(this.callerInfo.result.MemberNumber);
        this.second_subheaderHeight = this.$refs.second_subheaderHeight.clientHeight;
      } catch (error) {
        this.isLoadingWarningCode = false;
        /* console.log(error); */
      }
    },
    async populateWarningCodes(code) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        const body = {
          Company: this.company,
          WarningNumber: code,
          AuthToken: this.authToken,
        };
        // /GetWarningSelectFields
        // const resSymitarWarningCodes = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/GetWarningSelectFields.php`,
        //   body,
        //   { headers }
        // )
        const resSymitarWarningCodes = await api.post(
          "GetWarningSelectFields",
          body
        );

        if (resSymitarWarningCodes.status === 200) {
          if (resSymitarWarningCodes.data.Warnings) {
            this.symitarWarningCodes.push(resSymitarWarningCodes.data.Warnings);
          }
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    async fetchNotesCategory() {
      this.isLoadingNotes = true;
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        //PHP
        // const resNotesCategory = await axios.get(
        //   "https://genesys-api.gcm3.com/apiNODE/api_notescategory.php?action=get_notes_category_by_id&Company=1285",
        //   { headers }
        // )
        // if (resNotesCategory.status === 200) {
        //   this.noteCategories = resNotesCategory.data.result.map((item) => item.NoteCategory)
        //   console.log("NotesCategory by Company API result = ", resNotesCategory.data.result)
        //   console.log(this.noteCategories)
        //   this.isLoadingNotes = false
        // }
        // NODE START
        const { status, data } = await api.post("getNoteCategory", {
          Company: this.company,
        });

        if (status === 200) {
          this.noteCategories = data.map((item) => item.NoteCategory);
          this.isLoadingNotes = false;
        }
        // console.log(data, status)
      } catch (error) {
        /* console.log(error); */
      }
    },
    passValueAuth(value, event) {
      // Here you can access the passed value and perform any necessary operations
      /* console.log(value); */ // Output the value in the console for demonstration purposes
      this.AuthclickedButton = value;

      if (value === 3) {
        // Manually Authenticated
        this.AuthclickedButton = 3;
      } else if (value === 4) {
        // Manually Failed
        this.AuthclickedButton = 4;
      }
      event.preventDefault();
    },

    // Need to Revise this Section to Remove +1 because it's
    // Already in the backend
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (cleaned.length <= 5) {
        return; // Return undefined if length is 5 or below
      }
      if (match) {
        // Always prepend '+1' to the phone number
        let intlCode = "+1 ";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
      return str;
    },
    readCallerAuthData(authStatusNum, attemptNum, PhoneNumbers, CallerID) {
      if (authStatusNum === "0") {
        this.authStatus = "Not Authenticated";
        this.authStatusColor = "red";
        this.authStatus_num = "0";
      } else if (authStatusNum === "1") {
        this.authStatus = "Authenticated"; // Authenticated
        this.authStatusColor = "green";
        this.authStatus_num = "1";
      } else if (authStatusNum === "2") {
        this.authStatus = "Not Authenticated"; // Not Authenticated
        this.authStatusColor = "red";
        this.authStatus_num = "2";
      } else if (authStatusNum === "3") {
        this.authStatus = "Manually Authenticated";
        this.authStatusColor = "black";
        this.authStatus_num = "3";
      } else if (authStatusNum === "4") {
        this.authStatus = "Manually Failed";
        this.authStatusColor = "red";
        this.authStatus_num = "4";
      } else {
        this.authStatus = "Invalid Status";
        this.authStatusColor = "red";
        this.authStatus_num = "5";
      }
      /* console.log('attemptNum', attemptNum); */
      if (attemptNum === "0") {
        this.attemptType = "Not Attempted";
      } else if (attemptNum === "1") {
        this.attemptType = "Attempted";
      } else if (attemptNum === "2") {
        this.attemptType = "Manually Attempted";
      } else {
        this.attemptType = "Invalid Attempt Value";
      }

      try {
        /* console.log('reached PhoneNumbers', PhoneNumbers); */
        const formattedCallerID = this.formatPhoneNumber(CallerID);
        let cellPhoneNumberHTML = '';
        PhoneNumbers = PhoneNumbers ? PhoneNumbers : { cellPhones: [], other: [] };

        /* console.log('reached PhoneNumbers again', PhoneNumbers); */
        for (let i = 0; i < PhoneNumbers.cellPhones.length; i++) {
          PhoneNumbers.cellPhones[i] = this.formatPhoneNumber(PhoneNumbers.cellPhones[i]);
        }

        for (let i = 0; i < PhoneNumbers.other.length; i++) {
          PhoneNumbers.other[i] = this.formatPhoneNumber(PhoneNumbers.other[i]);
        }

        const cellPhones = PhoneNumbers.cellPhones;
        this.cellPhones = [...this.cellPhones, ...PhoneNumbers.cellPhones[0].split(',')];
        // console.log('this.cellPhones ', this.cellPhones)
        const enrolledNumbers = [...new Set(PhoneNumbers.other)].filter(number => number !== undefined);
        let phonesToCheck = [...enrolledNumbers];
        for (let i = 0; i < cellPhones.length; i++) {
          let cellPhone = cellPhones[i];
          const cellPhonesArray = cellPhone.split(',');
          for (let i = 0; i < cellPhonesArray.length; i++) {
            let cellPhone = cellPhonesArray[i].trim();
            phonesToCheck.push(this.formatPhoneNumber(cellPhone));
            cellPhoneNumberHTML += `<p style="line-height:1; color:green; margin:0; padding:0; display: inline-block;">${this.formatPhoneNumber(cellPhone)}${i < cellPhonesArray.length - 1 ? '<p style=" display: inline-block; line-height:1; color:black; margin:0; padding:0;">,</p>' : ''}</p>`;
            // Add a comma after each phone number (except the last one)
            // if (i < cellPhonesArray.length - 1) {
            //   cellPhoneNumberHTML += ', ';
            // }
          }
        }

        let otherNumbersHTML = '';
        for (let i = 0; i < enrolledNumbers.length; i += 2) {
          otherNumbersHTML += `<p style="line-height:1; color:black; margin:0; padding:0; display: inline-block;">${enrolledNumbers.slice(i, i + 2).join(', ')}</p>`;
        }
        if (formattedCallerID) {
          if (phonesToCheck.includes(formattedCallerID)) {
            this.callerIdFlag = true;
            this.phonenumList = `The CallerID, ${formattedCallerID}, is found. <br><div style="gap:0px;display:flex;flex-wrap:wrap;justify-content:start; align-items:center;"><p style="line-height:1; display: inline-block; margin:0; padding:0; color:black">Enrolled numbers are:&nbsp;</p> ${cellPhoneNumberHTML}<br>${otherNumbersHTML}</div>`;
            // console.log(enrolledNumbers.includes(formattedCallerID))
          } else {
            this.callerIdFlag = true;
            if (phonesToCheck.length > 0) {
              this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. <br><div style="gap:0px;display:flex;flex-wrap:wrap;justify-content:start; align-items:center;"><p style="line-height:1; display: inline-block; margin:0; padding:0; color:black">Enrolled numbers are:&nbsp;</p> ${cellPhoneNumberHTML}<br>${otherNumbersHTML}</div>`;
            } else {
              this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. <br>Phone List is empty`;
            }
          }
        } else {
            this.callerIdFlag = false;
          if (phonesToCheck.length > 0) {
            this.phonenumList = `The CallerID is empty.<br> <div style="gap:0px;display:flex;flex-wrap:wrap;justify-content:start; align-items:center;"><p style="line-height:1; display: inline-block; margin:0; padding:0; color:black">Enrolled numbers are:&nbsp;</p> ${cellPhoneNumberHTML}<br>${otherNumbersHTML}</div>`;
          } else {
            this.phonenumList = "The CallerID is empty and Phone List is empty";
          }
        }
        this.enrolledNumbers = phonesToCheck;

      } catch (error) {
        const formattedCallerID = this.formatPhoneNumber(CallerID);
        this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. Phone List is empty`;
        console.error("Error:", error);
      }
    },

    async authenticateMethod(
      charCount,
      inpputedNo,
      callerID,
      authToken,
      companyID
    ) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      // let authenticationMethod = 0
      // let creditCardNumber = null
      let memberNumber = null;
      if (charCount === 16) {
        /* console.log("credit card detected"); */
        // authenticationMethod = 2
        // creditCardNumber = inpputedNo
      } else if (charCount !== 9) {
        /* console.log("member number detected"); */
        // authenticationMethod = 0
        memberNumber = inpputedNo;
      } else {
        // creditCardNumber = inpputedNo
      }
      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/Authenticate.php`,
        //   {
        //     AuthenticationMethod: authenticationMethod,
        //     CreditCardNumber: creditCardNumber,
        //     MemberNumber: memberNumber,
        //     CallerID: callerID,
        //     AuthToken: authToken,
        //     Company: companyID,
        //   },
        //   { headers }
        // )
        const res = await api.post("MemberAppHandleSend", {
          MemberNumber: memberNumber,
          CallerID: callerID,
          AuthToken: authToken,
          Company: companyID,
        });

        if (res.status === 200) {
          if (res.data.Response == "YES" && res.data.WarningCodes) {
            /* console.log("Authenticate.php result = ", res.data); */
            this.userWarningCodes = res.data.WarningCodes.split(",");
            /* console.log("User warning codes: ", this.userWarningCodes); */
            return res.data;
          } else {
            return res.data;
          }
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    async AddAuthCall(FailureReason, SuccessOrFail, CallerInfo) {
      /* console.log("FailureReason", FailureReason); */
      /* console.log("SuccessOrFail", SuccessOrFail); */
      /* console.log("CallerInfo", CallerInfo); */
      /* console.log("AgentId", CallerInfo.AgentId); */
      /* console.log("AgentName", CallerInfo.AgentName); */

      try {
        const res = await api.post("insertAuthCalls", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          CallerID: CallerInfo.CallerID,
          AuthMethod: 2,
          SuccessOrFail: SuccessOrFail,
          Company: this.company,
          InteractionId: CallerInfo.InteractionId,
          FailureReason: FailureReason,
          CreditCardNumber: CallerInfo.CreditCardNumber,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        });

        /* console.log(res); */

        if (res.status === 200) {
          /* console.log("AddAuthCalls", res.data); */
          return res.data;
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    async UpdateCallsByAuthToken(
      FailureReason,
      SuccessOrFail,
      CallerInfo,
      AuthToken
    ) {
      try {
        const res = await api.post("updateCallsAuthStatus", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          AuthToken: AuthToken,
          AuthStatus: SuccessOrFail,
          FailureReason: FailureReason,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        });

        if (res.status === 200) {
          /* console.log("update_calls result = ", res.data); */
          return res.data;
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    async UpdateCallerInfo(SuccessOrFail, AuthToken) {

      try {
        /* console.log(SuccessOrFail); */
        const res = await api.post("updateCallerInfoSetAuthStatus", {
          AuthStatus: SuccessOrFail,
          AttemptType: 2,
          IndexNum: AuthToken,
        });

        if (res.status === 200) {
          /* console.log("update_caller_info result = ", res.data); */
          return res.data;
        }
      } catch (error) {
        /* console.log(error); */
      }
    },
    handleCancel(value) {
      switch (value) {
        case "membernumber":
          this.inputNo = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Member number canceled successfully"
          );
          break;
        case "authstatus":
          this.AuthclickedButton = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Authentication status canceled successfully"
          );
          break;
        case "notecategory":
          this.notes = "";
          this.category = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Note Category canceled successfully"
          );
          break;
        case "accountnumber":
          this.accountNumberSelected = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Account Number canceled successfully"
          );
          break;
      }
      this.inputNo = '';
      this.selectedMemberType = null;
    },


    // Integrations Send SMS With OTP Function - #20240726JM
    async sendSMS() {
      try {
        // Format the phone number with the selected country code
        // if (this.selectedCountryCode && this.phoneSendOTP) {
        //   this.formatNumber = `${this.selectedCountryCode}${this.phoneSendOTP.replace(/[^+\d]/g, '')}`;
        /* console.log("phoneSendOTP", this.phoneSendOTP); */

        if (this.phoneSendOTP) {
          this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
        }

        //   this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
        // Save the formatted number to the formattedPhoneNumbers array
        // this.formattedPhoneNumbers.push(this.formatNumber);

        const response = await api.post("/integrations/send-verification-token", {
          company: this.company,
          cellPhone: this.formatNumber,
          authToken: this.authToken,
          memberNumber: this.callerInfo.result.MemberNumber,
        });

        const data = response.data;
        /* console.log(this.formatNumber); */
        /* console.log(data); */
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#FF7F50",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          data.Response
        );

        /* console.log("SMS sent successfully", data); */
        // } else {
        //   console.error("Country code or phone number is missing");
        // }
      } catch (error) {
        console.error(
          "Failed to send SMS",
          error.response ? error.response.data : error.message
        );

        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#FF7F50",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "Failed to send SMS"
        );
      }
    },
    // Integrations Verify OTP Function - #20240726JM
    async verifyOTP() {

      if (this.phoneSendOTP) {
        this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
      }

      try {
        const response = await api.post(
          "/integrations/validate-otp",
          {
            company: this.company,
            cellPhone: this.formatNumber,
            otp: this.otpInput,
            authToken: this.authToken,
          }
        );
        const data = response.data;
        this.verificationAttempted = true;
        this.isValid = data.Response === "OTP Validated";
        if (!this.isValid) {
          /* console.log("OTP verification failed", data); */

          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            data.Response
          );

        } else {
          /* console.log("OTP verified", data); */
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "OTP Verified Successfully"
          );
        }
      } catch (error) {
        this.verificationAttempted = true;
        this.isValid = false;
        console.error(
          "Failed to verify OTP",
          console.log(error),
          error.response ? error.response.data : error.message
        );
      }
    },
    // End of 2FA Function for 2FA
    async fetchWarningCodes() {
      this.isLoadingWarningCode = true;

      try {
        const result = await api.get(`/v3/warning/codes?company=${this.company}&authToken=${this.authToken}`);

        if (result.data.length > 0) {
          this.symitarWarningCodes = result.data;
        }
        else {
          this.symitarWarningCodes = [];
        }
      } catch (error) {
        /* console.log(error); */
        this.isLoadingWarningCode = false;
        this.symitarWarningCodes = [];
      }
      this.isLoadingWarningCode = false;
    },

    async getOptInPhoneNumberByMemberNumber() {

      try {
        const result = await api.get(`/integrations/opt-in/${this.callerInfo.result.MemberNumber}`);
        if (result.data) {
          result.data.Response.forEach(phoneNumber => {
            this.optInCellPhones[phoneNumber] = phoneNumber;
          });

          /* console.log('optInCellPhones:', this.optInCellPhones); */
        }
        else {
          /* console.log('getOptInPhoneNumberByMemberNumber result is blank', result); */
        }
      } catch (error) {
        /* console.log('getOptInPhoneNumberByMemberNumber', error); */
      }
    },

    async optIn() {
      let checkIfExist = false;
      for (const v of Object.values(this.optInCellPhones)) {
        if (v === this.selectedCountryCode + this.manualInputOptInNumber.replace(/\D/g, '')) {
          checkIfExist = true;
          break;
        }
      }

      if (checkIfExist) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#FF7F50",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "Phone Number Already Opt In"
        );

        return;
      }

      try {
        const result = await api.post(`/integrations/sms/opt-ins`, {
          memberNumber: this.callerInfo.result.MemberNumber,
          cellPhone: this.selectedCountryCode + this.manualInputOptInNumber.replace(/\D/g, ''),
          company: this.company,
        });
        await this.getOptInPhoneNumberByMemberNumber();
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#FF7F50",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          result.data.Response
        );

        this.manualInputOptInNumber = '';
        this.selectedCountryCode = '+1'
      } catch (error) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#FF7F50",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "Something went wrong"
        );

        console.log(error);
        this.manualInputOptInNumber = '';
        this.selectedCountryCode = '+1';
      }
    },

    async optOut() {
      console.log('phoneSendOTP', this.phoneSendOTP);
      if (this.phoneSendOTP) {
        this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
      }
      let idToDelete = '0';

      for (const [k, v] of Object.entries(this.optInCellPhones)) {
        if (v === this.phoneSendOTP) {
          idToDelete = k;
          break;
        }
      }

      if (idToDelete === '0') {
        /* console.log('Phone number not found in opt-in list.'); */
        return;
      }

      Swal.fire({
        title: 'Opt-Out Confirmation',
        html: `<p>Are you sure you want to opt-out phone number <br><b>${this.formatPhoneNumber(this.phoneSendOTP)}</b>?</p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.post('/integrations/sms/opt-outs', {
              memberNumber: this.callerInfo.result.MemberNumber,
              cellPhone: this.formatNumber,
              company: this.company,
            });
            // Directly delete the property from the reactive object
            delete this.optInCellPhones[idToDelete];

            await this.getOptInPhoneNumberByMemberNumber();

            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              response.data.Response
            );
          } catch (error) {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Something went wrong"
            );
            console.log(error);
          }
        }
      });
    },
    async getCountryCode() {
      try {
        const result = await api.get(`/country-codes`);
        /* console.log('result countryCodes', result.data); */
        this.countryCodes = result.data.map(item => item.code);
        /* console.log('result this.countryCodes', this.countryCodes); */
      } catch (error) {
        console.error('Error fetching country codes:', error);
        this.countryCodes = [];
      }
    },
    handleNumericInput(event) {
      // Remove non-numeric characters from input
      this.manualInputOptInNumber = event.target.value.replace(/\D/g, '');
    },
    async getFraudNotes(MemberNumber) {
      try {
        const { data } = await api.get(`getFraudNotes?company=${this.company}&memberNumber=${MemberNumber}`)
        this.fraudNotes = data
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style scoped>
.flex-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 88vh;
  padding: 0;
  overflow-x: hidden !important;
}

#subheader {

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 0px;
  margin-top: 0px;
  width: 100%;
  justify-content: space-around;
}

#subheader3 {
  padding: 0px;
  height: 0px;
}

#subheader2 {

  display: flex;
  flex-flow: column;
}

#subheader span {
  width: 30%;
  padding: 8px;

}

#centerdiv {
  padding: 0 8px;
  margin-top: -25px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  justify-content: space-around;
}

.containerdiv {
  display: flex;
  flex-flow: column;
  height: 98%;
  width: 30%;
  padding: 8px;
}

.containerdiv>* {
  padding: 8px;
}

.scrollablediv {

  max-height: 300px;
  margin-top: 18px;
  overflow-y: auto;
}

/* #containerdiv1 {
  margin-top: 34px !important;
}

#containerdiv2 {
  margin-top: -70px !important;
} */

#containerdiv3 {
  display: flex;

  visibility: visible;
  margin-right: 18px;
  padding: 8px;
  height: auto;
}


.sidebar {
  padding: 0;
  z-index: 999;
  visibility: hidden;
  width: 350px;
  height: auto;
  background: #ffffff;
  position: fixed;
  top: -30px;
  right: -350px;
  transition: right 0.3s ease;
}

.sidebar.active {
  right: -20px;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
}

.open-btn {
  border-right: none;
  background-color: white;
  position: relative;
  top: 15vh;
  right: 28px;
  /*42px */
  font-size: 24px;
}

.open-btn:hover {
  cursor: pointer;

}


h4 {
  margin: 10px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

.member-number-label {
  margin: 10px 0;
}

header {
  display: flex;
  justify-content: space-between;
}

label {
  font-size: 1rem;
  margin: 0;
}

span {
  font-size: 1rem;
}




table {
  margin: 14px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

select {
  display: block;
  width: 100%;
}

textarea {
  display: block;
  width: 100%;
}




.solid-border {
  border: 1px solid black;
}

.member-info {
  display: flex;
}

/* .member-info span:first-of-type {
  width: 420px;
} */

.member-info-container {
  display: grid;
  grid-template-columns: 750px 1fr;
}

.member-info-table {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.member-info-table tr {
  display: flex;
}

.member-info-table th,
.member-info-table td {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}

.member-number-label {
  display: block;
}

.numberbadge {
  
  display: block;
  align-content: center;
  margin-left: 10px;
  text-align: center;
  width: 2em;
  height: 2em;
  border-radius: 24px;
  color: white;
  background-color: #0e5071;
  text-decoration: none;
}

.numberbadge:hover{
  cursor: pointer;
}



.btn-auth-group {


  display: flex;
  flex-flow: row;
  justify-content: space-evenly
}

.btn-manually-auth,
.btn-manually-failed {
  width: 50%;
  height: 50px;
  background-color: #a2a7ab;
  box-shadow: 2px 4px #ccc;
  border-radius: 20px;
  border: 0;
  margin: 0 12px 0 0;

}

.btn-save {
  margin-top: 7px;
}

.warning-code-col {
  align-self: center;

}

#warning-code-container {
  height: auto;
  margin-top: 15px;
  width: 100%;
}

.border-black {
  border: 1px solid black;
}

.description {
  font: -webkit-control;
}

.body-container {
  align-self: unset;
  height: auto;
  width: auto;
  min-height: calc(100vh - 16vh);
  /* height: 100%; */
  /* min-width: 1800px; */
}

.row {
  margin-bottom: 1rem;
}

.col {
  display: grid;
}

.col>div:nth-of-type(2) {
  justify-self: flex-end;
  height: 44px;
}

.col-left {
  display: inline-block;
  width: 400px;
  float: left;
}

.col-right {
  display: inline-block;
}

.select-categories {

  font-size: medium;
  padding: 5px;
}

#note-category {
  width: 98%;
}

.btn-clicked-green {
  background-color: #33cc66;
}

.btn-clicked-red {
  background-color: red;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.form {
  display: flex;
  align-items: center;
}

.button-disabled {
  /* Apply your disabled button styles here */
  box-shadow: 2px 4px #ccc;
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity/darkness as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Adjust the z-index as needed */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text-large {
  font-size: 15px;
  color: white;
}

.btn {
  box-shadow: 2px 4px #ccc;
  margin: 0 5px;
  padding: 7px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  width: 70px;
}

.btn-cancel {
  margin-left: 10px;
}



.warning-code-table {
  /* width: fit-content !important; */
  /* width: 330px !important; */
  width: 100%;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  z-index: 2;
  /* Higher than the overlay */
  text-align: center;
}

.modal-image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  /* Add this to show a pointer cursor on the image */
}

.membernumber-icon {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-left: -8px;
  padding-right: 4px;
  /* Add this to show a pointer cursor on the image */
}

#copymemberbtn{
  border:none;
  background: white;
  vertical-align: middle;
  margin-bottom: 4px;
}

#copyidbtn{
  border:none;
  background: white;
  vertical-align: middle;
  margin-bottom: 4px;
}

.arrow {
   transform: translateX(1px);
   transform: translateY(1px);
}

.ttcopyidbtn{
    visibility: hidden;
    max-width: 300px;
    background-color: #3b3b3b;
    color: #ffffff;
    margin-top: 80px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    font-size: 14px;
    
}

@keyframes fade {
  0%{
    opacity: 0;
  }
  90%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.ttcopymembtn{
    visibility: hidden;
    max-width: 300px;
    background-color: #3b3b3b;
    color: #ffffff;
    margin-top: 35px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    font-size: 14px;
}

#copymemberbtn:hover .ttcopymembtn {
  animation: fade 1s ease-in forwards;
  visibility: visible;
  opacity: 1;
}

#copyidbtn:hover .ttcopyidbtn {
  animation: fade 1s ease-in forwards;
  visibility: visible;
  opacity: 1;
}

.copyidbtn_class {
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  border-radius: 4px;
  padding: 0 6px 0 6px;
  background-color: #646464;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.box {
    position: relative;
    padding: 0px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



#logocontainer {
  
}

#subheader2

.close {
  position: absolute;
  top: 100px;
  /* Adjust the top position as needed */
  right: 190px;
  /* Adjust the right position as needed */
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Adding Styling for 2FA */

/* Whole 2FA Container */
.flex-container {
  display: flex;
  justify-content: flex-end;
  width: 94%;
}

/* 2FA Border and Label */
.two-fa-container {
  border: 2px solid #767676;
  padding: 20px;
  border-radius: 5px;
  height: fit-content;
  width: 100%;
  box-shadow: 2px 4px #ccc;
}

.two-fa-container>.flex {
  width: 100%;
  margin-top: -35px;
}

.two-fa-container:hover {
  border: 2px solid rgba(0, 0, 0, 0.87);
}


.opt-in-group {
  width: 100%;
}

.opt-in-group select {
  width: 30%;
  margin-right: 10px
}

.opt-in-group button {
  width: 100px;
}

.opt-in-input-group {
  display: flex;
  align-items: center;
}

.send-message-group {
  width: 100%;
  margin-bottom: 10px;
}

.send-message-group select {
  margin-bottom: 12px;
  margin-top: 10px;
}

.two-fa-label {
  position: relative;
  top: -40px;
  /* Adjust this value to place the label correctly */
  left: -10px;
  /* Adjust this value to center the label */
  background: white;
  /* Same color as the container background */
  padding: 0 5px;
  /* Small padding to create space around the text */
  font-size: 14px;
  width: 25px;
  /* Adjust font size as needed */
}

/* End of 2FA Border and Label */

.flex-container,
#warning-code-container {
  width: 94%;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.otp-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verification-status {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.status-icon {
 
  width: 27px;
  height: 27px;
}

/* Radio Buttons Style */
.radiolist {
  display: flex;
}

.radiolist div {
  padding-right: 1rem;
  padding-bottom: .5rem;
}

.radiolist div input[type="radio"] {
  margin: 0 !important;
  margin-right: 6px !important;
  padding: 0 !important;
  width: auto;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: fixed;
  z-index: 1051;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: auto;
  margin: 0;
  pointer-events: none;
}

.modal-lg {
  max-width: 800px;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #ffffff;
  /* Updated color */
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #fff;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  background-color: #fff;
  /* Updated color */
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* Updated color */
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  pointer-events: auto;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
}

.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal-backdrop.show {
  opacity: 1;
  pointer-events: auto;
}

dialog {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  position: fixed;
  /* left: -10px; */
  margin-left: 25px;
  top: 25%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

.dialog-header {
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -10;
}

.dialog-body {
  z-index: 10;
  background-color: #fff;
  padding: 2rem;
  /* border-radius: 6.4px; */
  overflow-y: auto;
  /* Add a vertical scroll bar when content exceeds the height */
  max-height: 80vh;
  /* Limit the maximum height of the dialog body */
}

.screen-pop-sm {
  display: none;
}



@media only screen and (min-width: 800px) and (max-width: 1200px) {

  .sidebar {
    visibility: visible;
  }



  #centerdiv {
    width: 100%;
    padding: 12px;
    justify-content: start;
  }

  #containerdiv1 {
    flex: 1 45%
  }

  #containerdiv2 {
    flex: 1 45%
  }

  #containerdiv3 {
    visibility: visible;
    width: 50%;
    margin-top: 0px !important;
    flex-direction: row;


  }

  #warning-code-container {
    margin-top: -30px;
  }

  #subheader1 {
    flex-basis: 45%;
  }

  #subheader2 {
    flex-basis: 45%;
  }

  #subheader3 {
    display: none;
  }
}

header>div {

  padding-top: 10px;

}

@media only screen and (max-width: 800px) {

  .sidebar {
    visibility: visible;
  }

  .sidebar.active {
    right: -10px;
  }



  #centerdiv {
    margin-top: 0px;
  }

  #containerdiv1 {
    flex-basis: 100%;
    margin: -20px 0px 0px !important;
    padding: 0px;
  }

  #containerdiv2 {
    flex-basis: 100%;
    margin: 0px !important;
    padding: 0px;
  }

  #containerdiv3 {
    visibility: visible;
    flex-basis: 100%;
    margin: 20px 0px 0px !important;
    padding: 0px;
  }

  #subheader1 {
    flex-basis: 100%;
    padding: 0px;
  }

  #subheader2 {
    flex-basis: 100%;
    padding: 0px;
  }

  #subheader3 {
    display: none;
    /* flex-basis: 100%;
    padding: 0px; */
  }

  .membernumber-icon {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    /* Add this to show a pointer cursor on the image */
  }

  .body-container {
    padding-left: 0px;
    padding-right: 12px;
  }

  .flex-container,
  #warning-code-container {
    width: 95%;
  }

  #phonenum_list {
    display: block;
    font-size: 14px;
    line-height: 1;
  }




  header {
    padding-left: 15px;
  }

  .screen-pop-lg {
    display: none;
  }

  .screen-pop-sm {
    font-size: 1rem;
    display: block;
  }

  #authstatus_value {
    font-size: 0.9em;
  }

}
</style>
