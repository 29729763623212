<template>
  <div class="form-group">
    <input
      class="form-control"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "BaseInputCore",
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
}
</script>

<style scoped>
.form-group {
  display: inline-block;
  width: 100%;
}

.form-group input {
  font-weight: 400;
  /*  margin-bottom: 20px;*/
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 140%;
  position: relative;
  border-radius: 4px;
  /*  padding: 16.5px 14px;*/
  padding: 8px 5px;
  box-shadow: 2px 4px #ccc;
  /*  border: 2px solid #0e5071;*/
}

.form-group input:read-only {
  font-weight: 400;
  /*  margin-bottom: 20px;*/
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 140%;
  position: relative;
  border-radius: 4px;
  /*  padding: 16.5px 14px;*/
  padding: 8px 5px;
  box-shadow: 2px 4px #ccc;
  /*  border: 2px solid #0e5071;*/
  background-color: rgb(240, 240, 240);

}
</style>
