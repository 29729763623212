<script setup>
import Header from './Header.vue'
import SecurityInformationBox from './SecurityInformationBox.vue'
import api from '../api/api.js'
import Swal from 'sweetalert2'
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
</script>

<template>
    <div class="body-container">
        <!-- <div class="input-group">
            <label for="authtoken">AuthToken</label>
            <input type="text" v-model="authToken" name="authtoken" />
        </div>
        <div class="input-group">
            <label for="company">Company</label>
            <input type="text" v-model="company" name="company" />
        </div>
        <div>
            <button @click="getCallerInfo()">Submit</button>
        </div> -->
                    <div class="row-header">
                <h2>Security Information</h2>
                <!-- <div>
                    <label for="">Member Number:</label>
                    <span>&nbsp;{{ callerInfo.MemberNumber }}</span>
                </div>
                <div>
                    <label for="">Name: </label>
                    <span>&nbsp;{{ callerInfo.MemberName }} </span>
                </div> -->
                <h2>SI01</h2>
            </div>
        <div id="out_of_wallet_questions_container_1">

            <!-- START GRID -->
            <div class="questions-container">
                <div class="col-1">
                    <div class="row-flex">
                        <div class="">
                            <h4 class="question-header">Question</h4>
                        </div>

                        <div class="">
                            <h4 class="response-header">Response</h4>
                        </div>
                    </div>
                    <!-- <div class="row-flex"> -->
                    <form action="" class="" @click.capture="setAuthenticate" ref="formEl">
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> What is your current occupation? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q1_c" name="q1" value="Correct" />
                                    <label class="" for="q1_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q1_i" name="q1" value="Incorrect" />
                                    <label class="form-check-label" for="q1_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q1_na" name="q1" value="Not Asked" checked />
                                    <label class="" for="q1_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm">
                                    Who else is on this account with you? If more than one person, only have to verify
                                    one?
                                </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q2_c" name="q2" value="Correct" />
                                    <label class="" for="q2_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q2_i" name="q2" value="Incorrect" />
                                    <label class="form-check-label" for="q2_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q2_na" name="q2" value="Not Asked" checked />
                                    <label class="" for="q2_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> Date of birth for another signer on the account? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q3_c" name="q3" value="Correct" />
                                    <label class="" for="q3_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q3_i" name="q3" value="Incorrect" />
                                    <label class="form-check-label" for="q3_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q3_na" name="q3" value="Not Asked" checked />
                                    <label class="" for="q3_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> What is your monthly payment on your loan? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q4_c" name="q4" value="Correct" />
                                    <label class="" for="q4_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q4_i" name="q4" value="Incorrect" />
                                    <label class="form-check-label" for="q4_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q4_na" name="q4" value="Not Asked" checked />
                                    <label class="" for="q4_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm">
                                    What is the year, make and model of the loan on your account?
                                </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q5_c" name="q5" value="Correct" />
                                    <label class="" for="q5_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q5_i" name="q5" value="Incorrect" />
                                    <label class="form-check-label" for="q5_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q5_na" name="q5" value="Not Asked" checked />
                                    <label class="" for="q5_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm">
                                    Where do you receive direct deposit from? Only need to verify one of the deposits
                                </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q6_c" name="q6" value="Correct" />
                                    <label class="" for="q6_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q6_i" name="q6" value="Incorrect" />
                                    <label class="form-check-label" for="q6_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q6_na" name="q6" value="Not Asked" checked />
                                    <label class="" for="q6_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> What bank is your auto pay coming from? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q7_c" name="q7" value="Correct" />
                                    <label class="" for="q7_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q7_i" name="q7" value="Incorrect" />
                                    <label class="form-check-label" for="q7_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q7_na" name="q7" value="Not Asked" checked />
                                    <label class="" for="q7_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm">
                                    How do you make deposits/payments? (shared branching, mobile, teller, Drachmae)
                                </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q8_c" name="q8" value="Correct" />
                                    <label class="" for="q8_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q8_i" name="q8" value="Incorrect" />
                                    <label class="form-check-label" for="q8_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q8_na" name="q8" value="Not Asked" checked />
                                    <label class="" for="q8_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm">
                                    What automatic withdrawals do you have coming out of your account?
                                </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q9_c" name="q9" value="Correct" />
                                    <label class="" for="q9_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q9_i" name="q9" value="Incorrect" />
                                    <label class="form-check-label" for="q9_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q9_na" name="q9" value="Not Asked" checked />
                                    <label class="" for="q9_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> What is the code word? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q10_c" name="q10" value="Correct" />
                                    <label class="" for="q10_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q10_i" name="q10" value="Incorrect" />
                                    <label class="form-check-label" for="q10_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q10_na" name="q10" value="Not Asked" checked />
                                    <label class="" for="q10_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                        <div class="row-flex">
                            <div class="question-col">
                                <label for="colFormLabelSm"> What dealership did you buy this vehicle from? </label>
                            </div>
                            <div class="answer-col">
                                <div class="">
                                    <!-- radio btn Correct -->
                                    <input class="" type="radio" id="q11_c" name="q11" value="Correct" />
                                    <label class="" for="q11_c"> Correct </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Incorrect -->
                                    <input class="" type="radio" id="q11_i" name="q11" value="Incorrect" />
                                    <label class="form-check-label" for="q11_i"> Incorrect </label>
                                </div>
                                <div class="">
                                    <!-- radio btn Not asked -->
                                    <input class="" type="radio" id="q11_na" name="q11" value="Not Asked" checked />
                                    <label class="" for="q11_na"> Not Asked </label>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <!--  -->
        <div id="out_of_wallet_questions_container_2">
            <!-- <form action="" method="POST" @submit.prevent="authenticateCaller"> -->
     <form action="" method="POST" @submit.prevent="">
                <div id="security_row_1">
                    <!-- <button
                        @click="authStatus = '3'"
                        name="btn_authenticate1"
                        type="button"
                        id="btn_authenticate1"
                        :class="classAuthenticate"
                        :disabled="!isAuthenticate">
                        Manually Authenticated
                    </button>
                    <button
                        @click="this.authStatus = '4'"
                        name="btn_failed_authenticate1"
                        type="button"
                        id="btn_failed_authenticate1"
                        :class="classFailedAuth">
                        Manually Failed
                    </button>
                    <button id="btn_submit_out_of_wallet" type="submit" name="btn_authstatus1" class="btn btn-primary">
                        Save
                    </button> -->
                    <ButtonSubmit label="Save" />
                    <ButtonCancel label="Cancel" />
                </div>
            </form>
            <!-- START MANUALLY AUTH & MANUALLY FAILED & SAVE BUTTON  -->
        </div>
        <!-- START SECURITY NOTES  -->
        <!-- <form method="POST" @submit.prevent="addFraudNote">
            <h5 id="enter-notes1" class="security-notes-header">Security Notes</h5>
            <div id="suspicious-row">
              
                <div class="">
                    <label for="suspicious">Suspicious</label>
                    <input id="suspicious" type="checkbox" v-model="isSuspicious" />
                </div>
      
                <div class="">
                    <select class="form-select" v-model="noteCategory">
                        <option value="" disabled selected>Security Questions</option>
                        <option value="Not on Account">Not on Account</option>
                        <option value="Unable to verify account details">Unable to verify account details</option>
                        <option value="Suspected fraud - Required notes">Suspected fraud - Required notes</option>
                        <option value="Suspicious Behavior - Required Notes">
                            Suspicious Behavior - Required Notes
                        </option>
                        <option value="Other - Require notes">Other - Require notes</option>
                    </select>
                </div>
            </div>
            <div id="notes-row" class="">
                <div class="">
                    <label for="txt-notes"> Note: </label>
                </div>
                <div class="">
                    <textarea class="form-control" v-model="notes" placeholder="Note" id="txt-notes"></textarea>
                </div>
                <div class="">
                    <button type="submit" class="btn btn-primary" name="button1" id="btn_note_save">Save</button>
                </div>
            </div>
        </form> -->
        <!-- END SECURITY NOTES  -->
    </div>
</template>

<script>
export default {
    name: 'SecurityInformation',
    component: [Header, SecurityInformationBox,ButtonCancel,ButtonSubmit],
    data: () => {
        return {
            company: '32569',
            authToken: '',
            callerInfo: [],
            isAuthenticate: false,
            authStatus: '',
            isSuspicious: '',
            noteCategory: '',
            notes: '',
        }
    },
    props: {
        userData: Object,
        brandData: Object,
    },
    async mounted() {
        this.getCallerInfo()
    },
    computed: {
        classFailedAuth() {
            return {
                'btn-danger': this.authStatus == '4',
                'btn-secondary': this.authStatus != '4',
            }
        },
        classAuthenticate() {
            return {
                'btn-success': this.authStatus == '3',
                'btn-secondary': this.authStatus != '3',
            }
        },

        getSuspicious() {
            return this.isSuspicious === true ? 'Y' : 'N'
        },
    },
    methods: {
        setAuthenticate() {
            let checkedCount = 0
            this.$refs.formEl.querySelectorAll('input[value="Correct"]').forEach((el) => {
                if (el.checked === true) checkedCount++
            })

            if (checkedCount >= 3) {
                this.isAuthenticate = true
            }
        },
        async addFraudNote() {
            if (this.notes == '' || this.noteCategory == '') {
                return Swal.fire({
                    title: '',
                    text: 'Security note not added.',
                    icon: 'warning',
                })
            } else {
                const fraudNoteData = {
                    Company: this.company,
                    Name: this.callerInfo.MemberName,
                    MemberNumber: this.callerInfo.MemberNumber,
                    PhoneNumber: this.callerInfo.CallerID,
                    InteractionId: this.callerInfo.InteractionId,
                    Suspicious: this.getSuspicious,
                    NoteCategory: this.noteCategory,
                    NoteText: this.notes,
                    AgentID: 'System',
                }

                try {
                    const { status } = await api.post('addFraudNotes', fraudNoteData)
                    if (status === 200) {
                        Swal.fire({
                            title: '',
                            text: 'Security note successfully added.',
                            icon: 'success',
                        }).then(() => this.resetData())
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async authenticateCaller() {
            if (this.authStatus == '') {
                return Swal.fire({
                    title: '',
                    text: 'No authentication status selected.',
                    icon: 'warning',
                })
            } else {
                const authCallData = {
                    Company: this.callerInfo.Company,
                    MemberNumber: this.callerInfo.MemberNumber,
                    CallerID: this.callerInfo.CallerID,
                    AuthMethod: '2',
                    SuccessOrFail: this.authStatus,
                    InteractionId: this.callerInfo.InteractionId,
                    FailureReason: this.authStatus == '4' ? 'Wrong answers to out-of-wallet questions' : '',
                    CreditCardNumber: this.callerInfo.CreditCardNumber,
                }

                const callerInfoData = {
                    authstatus: this.authStatus,
                    attempttype: '2',
                    indexnum: this.authToken,
                }

                try {
                    const { status } = await api.post('addAuthCall', authCallData)
                    if (status == 200) {
                        const { status } = await api.post('updateCallerInfo', callerInfoData)
                        if (status == 200) {
                            Swal.fire({
                                title: '',
                                text: 'Authentication status successfully updated.',
                                icon: 'success',
                            }).then(() => this.resetData())
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        resetData() {
            this.isSuspicious = ''
            this.noteCategory = ''
            this.notes = ''
            this.isAuthenticate = false
            this.authStatus = ''
            this.$refs.formEl.querySelectorAll('input[value="Not Asked"]').forEach((el) => (el.checked = true))
        },
        async getCallerInfo() {
            try {
                const { data } = await api.post('getCallerInfoById', {
                    company: this.company,
                    indexNum: this.authToken,
                })
                this.callerInfo = data
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style scoped>
input[value='Correct']:checked {
    accent-color: green;
}
input[value='Incorrect']:checked {
    accent-color: red;
}

input[value='Not Asked']:checked {
    accent-color: grey;
}

h4 {
    margin: 0;
    font-size: 17.6px;
    margin-block-start: 0;
    margin-block-end: 0;
}

label {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
}
#security_row_1 {
    text-align: center;
}
#security_row_1 > button {
    margin: 0 5px;
}

#suspicious-row {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0;
}

#notes-row {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

#txt-notes {
    height: 100px;
    width: 500px;
}

.input-group {
    margin: 10px;
}
.input-group > input {
    padding: 5px;
    font-size: 1rem;
}

.row-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.row-header h2:nth-of-type(1) {
    flex: 1 0 100px;
}

.row-header div:nth-of-type(1) {
    flex-grow: 2;
}

.row-header div:nth-of-type(2) {
    flex-grow: 1;
}

.response-header {
    width: 326px;
}

.question-header {
    margin-right: 12px;
}

.description {
    font: -webkit-control;
}

.body-container {
  align-self: unset;
  /* height: calc(100vh - 16vh); */
  height:790px;
}

.security-notes-header {
    font-size: 20px;
    font-weight: 600;
    margin: 12px 0;
}

.question-col {
    display: inline-block;
    text-align: right;
}

.answer-col > div {
    display: inline-block;
    margin: 0 10px;
}

.row-flex {
    display: flex;
    gap: 20px 10px;
    margin: 15px 0;
    justify-content: flex-end;
}

.border-solid {
    border: 1px solid black;
}

.questions-container {
    display: grid;
    grid-template-columns: minmax(auto, 1200px);
    column-gap: 10px;
    justify-items: right;
    margin-bottom: 10px;
}

.btn {
    margin: 0 5px;
    padding: 7px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border: 0;
    width: 70px;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

button:disabled {
    pointer-events: none;
    opacity: 0.65;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}
header {
  display: flex;
  justify-content: space-between;
}
</style>
