<script setup>
import api from "../api/api.js";
import { flashMessage } from "../functions.js";
import CryptoJS from "crypto-js";
import BaseButton from "./UI/BaseButton.vue"
import ButtonSubmit from "./ButtonSubmit.vue";
import ButtonCancel from "./ButtonCancel.vue";
import Header from "./Header.vue";
</script>

<!--  -->
<template>
  <div class="body-container">
    <header>
    <h2>My Profile</h2>
    <h2>MP01</h2>
    </header>

    <div class="row"></div>
    <div class="row">
    <form autocomplete="off" v-show="toggle1" @submit.prevent="savingupdates">
    <div class="col1">

    <div class="row">
    <label for="name">First Name</label>
    <input
    class="text-input"
    type="text"
    id="firstname"
    v-model="userDetails.first_name"
    required
    @input="checkFormChanges"
    />
    </div>

    <div class="row">
    <label for="lastname">Last Name</label>
    <input
    class="text-input"
    type="text"
    id="lastname"
    v-model="userDetails.last_name"
    required
    @input="checkFormChanges"
    />
    </div>

    <div class="row">
    <label for="email">Email</label>
    <input
    class="text-input"
    type="email"
    id="email"
    v-model="userDetails.email"
    required
    @input="checkFormChanges"
    />
    </div>

    <div class="row">
    <label for="startScreen" v-show="toggle1">Startup Screen</label>
    <select id="selectHomescreen" v-model="userDetails.home_screen" @change="checkFormChanges">
    <option :value="null" disabled selected>
    Choose Startup Screen
    </option>
    <option
    v-for="screen in availableScreens"
    :value="screen.value"
    :key="screen.value"
    >
    {{ screen.label }}
    </option>
    </select>
    </div>

    <div class="row">
  <label for="timezone">Timezone</label>
  <select
    id="selectTimezone"
    v-model="userDetails.time_zone"
    @change="handleChange($event)"
  >
  <!-- Removing/Commenting Timezone Offset here reference is Sikorsky - Timezone modify #03062024MS -->
    <option selected :value="null">Select Timezone</option>
    <option v-for="data in sortTimezoneList" :key="data">
      {{ data.timezone_name }}
    </option>
  </select>
</div>


    <div class="row">
    <label for="newPass" class="form-label">New Password</label>
    <div class="password-input-container">
    <input
    class="password-input"
    type="password"
    id="newPass"
    v-model="userDetails.checkPassword"   @input="checkFormChanges"
    />
    <label @click="showcheckPassword" class="password-toggle">
    <i
    class="fa-solid fa-eye"
    id="togglePassword"
    ></i>
    </label>
    </div>
    </div>

    <div class="row">
    <label for="confirmPass" class="form-label">Confirm Password</label>
    <div class="password-input-container">
    <input
    class=" password-input"
    type="password"
    id="confirmPass"
    v-model="userDetails.confirmPass" @input="checkFormChanges"
    />
    <label @click="showconfirmPass" class="password-toggle">
    <i class="fa-solid fa-eye" id="togglePassword" 
    ></i>
    </label>
    </div>
    </div>    
    </div>

    <div class="col2">
    <div class="btn-container">
    <ButtonSubmit label="Save" type="submit" />
    <ButtonCancel type="button" @click="resetFormFields" label="Cancel" :disabled="!unsavedChanges" />

    </div>
    </div>
    </form>
    </div>
    </div>
</template>

<!-- script  -->
<script>
export default {
  name: "MyProfileNew",
  component: [ButtonSubmit, ButtonCancel, Header, BaseButton],
  
  data: () => {
    return {
      unsavedChanges: false, 
      toggle1: true,
      first_name: "",
      last_name: "",
      email: "",
      home_screen: "",
      time_zone: "",
      currentHomescreen: "",
      chosenTimezone: null,
      timezoneList: [],

      userDetails: {
        first_name: "",
        last_name: "",
        email: "",
        checkPassword: "",
        confirmPass: "",

        roleScreens: [
          {
            roleID: "1",
            screens: [
              "My Profile",
              "Member Information",
              "History",
              "Notes",
            ],
          },
          // {
          //   roleID: "4",
          //   screens: ["My Profile", "Notes", "Manage User"],
          // },
          // {
          //   roleID: "3",
          //   screens: ["My Profile"],
          // },
          // {
          //   roleID: "2",
          //   screens: ["My Profile"],
          // },
          {
            roleID: "2",
            screens: [
              "My Profile",
              "Member Information",
              "History",
              "Notes",
              "Manage User",
              "Admin Dashboard",
              "IVR Config",
              "Note Category",
            ],
          },
        ],
      },
    };
  },


  props: {
    userData: Object,
    brandData: [],
  },


  methods: {
    checkFormChanges() {
    const userDetails = this.userDetails;
    const initialDetails = {
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      home_screen: this.home_screen,
      time_zone: this.time_zone,
      checkPassword: '',
      confirmPass: '',
    };
    for (const key in userDetails) {
      if (userDetails[key] !== initialDetails[key]) {
        this.unsavedChanges = true;
        return;
      }
    }
    this.unsavedChanges = false;
  },
  handleChange(event) {
    this.userDetails.time_zone = event.target.value;
    this.checkFormChanges();
    this.selectTimezone(event);
  },
  resetFormFields() {
    this.userDetails.first_name = this.first_name;
    this.userDetails.last_name = this.last_name;
    this.userDetails.email = this.email;
    this.userDetails.home_screen = this.home_screen;
    this.userDetails.time_zone = this.time_zone;
    this.userDetails.checkPassword = "";
    this.userDetails.confirmPass = "";
    this.unsavedChanges = false;
    flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        "Canceled successfully."
      )
  },

  showconfirmPass() {
    var y = document.getElementById("confirmPass"); 
    if (y.type === "password") {
    y.type = "text";
    } else {
    y.type = "password";
    }
  },

  showcheckPassword() {
    var y = document.getElementById("newPass"); 
    if (y.type === "password") {
    y.type = "text";
    } else {
    y.type = "password";
   }
  },

  async handleQuery() {
      await api.get("individuals/" + this.userData.ind_id).then((result) => {
        console.log("API Response:", result);

        this.first_name = result.data.first_name;
        this.last_name = result.data.last_name;
        this.email = result.data.email;
        this.home_screen = result.data.home_screen;
        this.time_zone = result.data.time_zone;

        this.userDetails.first_name = result.data.first_name;
        this.userDetails.last_name = result.data.last_name;
        this.userDetails.email = result.data.email;
        this.userDetails.home_screen = result.data.home_screen;
        this.userDetails.time_zone = result.data.time_zone;
      });
    },

    async checkmounted() {
      console.log("currentHomescreen", this.home_screen);
      await this.checkHomeScreen();
    },

    async checkHomeScreen() {
      if (this.currentHomescreen == "/my_profile") {
        this.home_screen = "My Profile";
      } else if (this.currentHomescreen == "/member_information") {
        this.home_screen = "Member Information";
      } else if (this.currentHomescreen == "/history") {
        this.home_screen = "History";
      } else if (this.currentHomescreen == "/security_information") {
        this.home_screen = "Security Information";
      } else if (this.currentHomescreen == "/notes") {
        this.home_screen = "Notes";
      } else if (this.currentHomescreen == "/manage_user") {
        this.home_screen = "Manage Users";
      } else if (this.currentHomescreen == "/upload_many_users_at_once") {
        this.home_screen = "Upload Bulk Users";
      } else if (this.currentHomescreen == "/elevated-users") {
        this.home_screen = "View Elevated Users";
      } else if (this.currentHomescreen == "/admin_dashboard") {
        this.home_screen = "Admin Dashboard";
      } else if (this.currentHomescreen == "/threshold_settings") {
        this.home_screen = "Threshold Settings";
      } else if (this.currentHomescreen == "/whitelist_phone") {
        this.home_screen = "Whitelist Phone";
      } else if (this.currentHomescreen == "/ivr_config") {
        this.home_screen = "IVR Config";
      } else if (this.currentHomescreen == "/warning_codes") {
        this.home_screen = "Warning Codes";
      } else if (this.currentHomescreen == "/manage-brand") {
        this.home_screen = "Manage Brand";
      } else if (this.currentHomescreen == "/note_category") {
        this.home_screen = "Note Category";
      } else if (this.currentHomescreen == "/company-configuration") {
        this.home_screen = "Company Configuration";
      } else {
        this.home_screen = "Default: My Profile";
      }
    },

    selectTimezone(e) {
      this.chosenTimezone = e.target.value;
      console.log(this.chosenTimezone);
    },

    async handleUpdateTimezone() {
      try {
        const res = await api.put(
          `individuals/timezone/${this.userData.ind_id}`,
          {
            time_zone: this.chosenTimezone,
            modified_by: this.userData.ind_id,
          }
        );
        if (res) {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#F47820",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Successfully updated timezone!"
          );
        }
      } catch (e) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#F47820",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          e.message
        );
      }
      this.toggle = !this.toggle;
    },

    async savingupdates() {
  console.log("Saving updates method called");
  const userDetails = this.userDetails;

  try {
    const response = await api.get(`individuals/${this.userData.ind_id}`);
    const existingPassword = response.data.password;

    if (userDetails.confirmPass) {
      if (userDetails.checkPassword !== userDetails.confirmPass) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#F47820",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "password do not match"
        );
      } else {
        const encryptedPassword = CryptoJS.AES.encrypt(
          userDetails.confirmPass,
          this.userData.seed
        ).toString();

        console.log("Encrypted Password:", encryptedPassword);

        const updatedData = {
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          email: userDetails.email,
          home_screen: userDetails.home_screen,
          time_zone: userDetails.time_zone,
          password: encryptedPassword,
          org_id: "",
          roles: ""
        };

        console.log("Updated Data:", updatedData);

        const response = await api.put(
          `/individuals/${this.userData.ind_id}`,
          updatedData
        );

        if (response.status === 200) {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#F47820",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Successfully updated"
          );
          // Adding this code block from Sikorsky and related to Timezone #03062024MS
          const localStorageData = JSON.parse(localStorage.getItem("passLoginUp"));
          console.log('passloginUp',localStorageData)
          let passLoginUp = localStorageData
          passLoginUp.first_name = userDetails.first_name;
          passLoginUp.last_name = userDetails.last_name;
          passLoginUp.email = userDetails.email;
          passLoginUp.time_zone = userDetails.time_zone;
          console.log('new passloginup', passLoginUp)
          localStorage.setItem("passLoginUp", JSON.stringify(passLoginUp))
          // End of the code block for Timezone #03062024MS

          this.first_name = userDetails.first_name;
          this.last_name = userDetails.last_name;
          this.email = userDetails.email;
          this.home_screen = userDetails.home_screen;
          this.time_zone = userDetails.time_zone;
          this.unsavedChanges = false; 
          this.userDetails.checkPassword = "";
          this.userDetails.confirmPass = "";
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#F47820",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Failed to Edit"
          );
        }
      }
    } else {
      const updatedData = {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        home_screen: userDetails.home_screen,
        time_zone: userDetails.time_zone,
        password: existingPassword,
        org_id: "",
        roles: ""
      };
      console.log("Updated Data:", updatedData);
      const response = await api.put(
        `/individuals/${this.userData.ind_id}`,
        updatedData
      );

      if (response.status === 200) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#F47820",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "Successfully updated"
        );
        // Adding this code block from Sikorsky and related to Timezone #03062024MS
        const localStorageData = JSON.parse(localStorage.getItem("passLoginUp"));
        let passLoginUp = localStorageData
        passLoginUp.first_name = userDetails.first_name;
        passLoginUp.last_name = userDetails.last_name;
        passLoginUp.email = userDetails.email;
        passLoginUp.time_zone = userDetails.time_zone;
        localStorage.setItem("passLoginUp", JSON.stringify(passLoginUp))
        // End of the code block for Timezone #03062024MS

        this.first_name = userDetails.first_name;
        this.last_name = userDetails.last_name;
        this.email = userDetails.email;
        this.home_screen = userDetails.home_screen;
        this.time_zone = userDetails.time_zone;
        this.unsavedChanges = false; 
      } else {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1
            ? this.brandData.accent_color1
            : "#F47820",
          this.brandData.flash_text_color
            ? this.brandData.flash_text_color
            : "#ffffff",
          "Failed to Edit"
        );
      }
    }
  } catch (error) {
    console.error("Error updating data:", error);
  }
},
  },


  async mounted() {
    console.log(this.userData.seed);
    this.handleQuery();
    this.checkHomeScreen();
    // JAS COMMENT 6-9-23 COZ get-time-zone endpoint not working displaying 404 error message
    try {
      const res = await api.get(`get-time-zone`);
      if (res) {
        this.timezoneList = res.data;
        console.log(this.timezoneList);
      }
    } catch (e) {
      flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
        this.brandData.flash_text_color
          ? this.brandData.flash_text_color
          : "#ffffff",
        e.message
      );
    }
    try {
      const res = await api.get(`individuals/${this.userData.ind_id}`);
      if (res) {
        this.chosenTimezone = res.data.time_zone;
      }
    } catch (e) {
      flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
        this.brandData.flash_text_color
          ? this.brandData.flash_text_color
          : "#ffffff",
        e.message
      );
    }
  },


  computed: {

    availableScreens() {
      let userRoles = Array.isArray(this.userData.roles)
        ? this.userData.roles
        : this.userData.roles.split(", ");

      // Filter the roleScreens based on the user's roles and flatten the screens array
      const screens = Array.from(
        new Set(
          userRoles.flatMap((roleID) => {
            const roleScreen = this.userDetails.roleScreens.find((role) => role.roleID === roleID);
            return roleScreen ? roleScreen.screens : [];
          })
        )
      );

      // Generate an array of options with the value and label properties
      return screens.map((screen) => ({
        value: `/${screen.toLowerCase().replace(/\s+/g, "_")}`,
        label: screen,
      }));
    },
    sortTimezoneList() {
      return this.timezoneList.sort();
    },
  },
};
</script>


<style scoped>

header {
  display: flex;
  justify-content: space-between;
}
label {
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  grid-column: 1/5;
  margin: 10px;
}
input {
  line-height: 1.5;
  font-size: 1rem;
  padding: 8px 5px;
  width: 100%;
  grid-column: 5/-1;
  outline: none;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 2px 4px #ccc;
  box-sizing: border-box;
  border-radius: 4px;
}
select {
  line-height: 1.5;
  font-size: 1rem;
  padding: 8px 5px;
  width: 100%;
  grid-column: 5/-1;
  outline: none;
  border: 2px solid black;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 2px 4px #ccc;
  box-sizing: border-box;
  border-radius: 4px;
}
form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 40px 10px 0;
  gap: 0 2rem;
}
form .row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  width: 80%;
}
.body-container {
  align-self: unset;
}
.btn-container {
  display: flex;
  justify-content: center;
  gap: 0;
  height: 40px;
  margin-top: 5px;
}
.row {
  margin-bottom: 20px;
}
.col1 {
  grid-column: 1/10;
}
.col2 {
  grid-column: 1/10;
  grid-row: 2/3;
  grid-gap: 20px; 
}

.password-input-container {
  position: relative;
  display: inline-block;
  line-height: 1.5;
  font-size: 1rem;
  padding: 8px 5px;
  width: 102%;
  grid-column: 5/-1;
  outline: none;
  margin-left: -1%;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: -10px
}
.password-toggle {
  position: absolute;
  left: calc(100% - 30px); 
  top: 50%; 
  transform: translate(-100%, -87%); 
  cursor: pointer;
  }
.fa-eye {
  font-size: 14px;
  color: #474141;
}
.fa-eye.active {
  color: #007bff;
}
</style>

