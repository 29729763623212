<template>
  <button class="btn-delete">{{ label }}</button>
</template>

<script>
export default {
  name: "ButtonDelete",
  props: {
    label: String,
  },
}
</script>

<style scoped>
button {
  border: 1px;
  box-shadow: 2px 4px #ccc;
  height: 35px;
  border-radius: 20px;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-size: 14px;
  padding: 0.575rem 0.75rem;
  border-radius: 20px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
.btn-delete:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
</style>
